.contactIcon {
    cursor: pointer;
    position: fixed;
    display: block;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 1;
    font-size: 35px;
    right: 0;
    top: 270px;
    z-index: 4;
    background: $lightest;
    color: black;
    padding-top: 20px;

    @include media-breakpoint-down(lg) {
        top: 215px;
    }

    @include media-breakpoint-down(md) {
        top: 170px;
    }

    @include media-breakpoint-down(xs) {
        top: 110px;
        width: 55px;
        height: 55px;
        padding-top: 15px;
        font-size: 30px;
    }
}

#contactContainer, #verifyContainer, #abortContainer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    background: $lightBlue;
    z-index: 11;
    color: white;
    font-family: $navigationFont;
    font-size: 24px;
    overflow: hidden;
    transition: height 0.3s;

    &.show {
        height: 100vh;
    }

    .invalid-feedback {
        display: block;
    }

    a {
        color: inherit;

        &:hover {
            text-decoration: underline;
            color: inherit;
        }
    }

    .closeIcon {
        cursor: pointer;
        position: absolute;
        display: block;
        width: 70px;
        height: 70px;
        text-align: center;
        line-height: 1;
        font-size: 30px;
        right: 0;
        top: 110px;
        z-index: 10;
        background: $lightest;
        color: black;
        padding-top: 22px;
    }

    .watermarkIcon  {
        position: absolute;
        display: block;
        width: 155px;
        height: 170px;
        text-align: center;
        line-height: 1;
        font-size: 153px;
        left: 0;
        top: 110px;
        z-index: 10;
        color: $ultraLightBlue;
    }

    .title {
        font-family: $headerFont;
        margin-top: 97px;
        margin-bottom: 150px;
        font-size: 66px;
        line-height: 1.2;
        text-transform: uppercase;
    }

    .phoneMail {
        font-size: 34px;
        line-height: 1.6;
        margin-bottom: 65px;
    }

    .searchContainer {
        .input-group {
            input {
                font-family: $mainFont;
                font-style: italic;
                font-size: 24px;
                border-radius: 0;
                background: none;
                border: 0;
                border-bottom: 1px solid white;
                color: white;
                padding-left: 0;

                &:focus {
                    box-shadow: none;
                }
            }

            .input-group-append {
                .input-group-text {
                    border-radius: 0;
                    background: none;
                    border: 0;
                    border-bottom: 1px solid white;
                    color: white;
                    font-size: 35px;
                    line-height: 0.9;
                }
            }
        }
    }

    .address {
        line-height: 1.4;
    }

    .links {
        a {
            display: block;
            line-height: 1.4;
            margin-bottom: 16px;
            clear: both;

            i {
                float: right;
                color: white;
                font-size: 55px;
                line-height: 0.65;
            }
        }
    }

    .fontSmaller {
        font-size: 15px;
    }

    .document {
        position: relative;
        display: block;
        color: white;
        text-decoration: none;
        text-align: left;
        padding-left: 60px;
        height: 50px;
        line-height: 50px;
        margin-bottom: 40px;
        overflow: hidden;

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            content: '\e00b';
            font-family: $dagobertFont;
            font-size: 50px;
            margin-right: 20px;
            line-height: 1;
        }

        &:hover {
            text-decoration: none;
            color: $lessDark;
        }
    }

    @include media-breakpoint-down(lg) {
        .closeIcon {
            top: 65px;
        }

        .watermarkIcon  {
            width: 140px;
            height: 154px;
            font-size: 138px;
            top: 90px;
        }

        .title {
            margin-top: 80px;
            margin-bottom: 110px;
            font-size: 54px;
        }
    }

    @include media-breakpoint-down(md) {
        font-size: 18px;

        .closeIcon {
            top: 50px;
        }

        .watermarkIcon  {
            width: 110px;
            height: 120px;
            font-size: 108px;
            top: 70px;
        }

        .title {
            margin-top: 60px;
            margin-bottom: 110px;
            font-size: 52px;
        }

        .phoneMail {
            font-size: 26px;
        }

        .searchContainer {
            .input-group {
                input {
                    font-size: 18px;
                }

                .input-group-append {
                    .input-group-text {
                        border-radius: 0;
                        background: none;
                        border: 0;
                        border-bottom: 1px solid white;
                        color: white;
                        font-size: 28px;
                        line-height: 0.9;
                    }
                }
            }
        }

        .links {
            a {
                &::after {
                    line-height: 0.50;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .title {
            margin-bottom: 90px;
        }

        .phoneMail {
            text-align: center;
        }

        .searchContainer {
            margin-bottom: 70px;
        }
    }

    @include media-breakpoint-down(xs) {
        font-size: 16px;

        .closeIcon {
            width: 55px;
            height: 55px;
            font-size: 23px;
            top: 30px;
            padding-top: 16px;
        }

        .watermarkIcon  {
            display: none;
        }

        .title {
            margin-top: 27px;
            margin-bottom: 45px;
            font-size: 30px;
        }

        .searchContainer {
            margin-bottom: 45px;
        }

        .phoneMail {
            font-size: 22px;
        }

        .address {
            margin-bottom: 40px;
        }

        .links {
            a {
                &::after {
                    line-height: 0.50;
                }
            }
        }
    }
}
