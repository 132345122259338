footer {
    border-top: 1px solid $light;

    img {
        margin: 50px 34px;
    }

    a {
        color: $default;
    }

    .footerData {
        background: $almostWhite;
        padding: 50px 0;

        .title {
            font-family: $headerFont;
            font-weight: 700;
            margin-bottom: 22px;
        }

        a {
            display: block;
            margin: 9px 0;
        }

        .buttonContainer {
            position: absolute;
            display: block;
            width: calc(100% - 30px);
            height: 100px;
            bottom: 0;
            left: 15px;

            .btn {
                margin-top: 8px;
            }
        }
    }

    .copy {
        padding: 15px 0;

        a {
            min-width: 48px;
        }
    }

    .external-links {
        ul {
            display: flex;
            gap: 5px;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                a {
                    display: block;
                    margin: 0;
                    transition: transform 0.2s ease;

                    @media (hover: hover) {
                        &:hover {
                            transform: translateY(-4px);
                        }
                    }

                    img {
                        display: block;
                        height: 32px;
                        margin: 0;
                        width: 32px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        font-size: 14px;

        img {
            margin: 50px 0px;
        }

        .footerData {
            .buttonContainer {
                .btn {
                    width: 100%;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        img {
            width: 50px;
        }
        .footerData {
            .buttonContainer {
                position: relative;
                width: 100%;
                left: 0px;
            }
        }
    }
}
