$singleProjectMarginTop: 120px;
$singleProjectMarginTopMd: 100px;
$singleProjectMarginTopSm: 80px;
$singleProjectMarginTopXs: 60px;

$singleProjectCardPadding: 20px;

$titleFontSize: 30px;
$titleFontSizeXs: 24px;

$locationFontSize: 14px;

$descriptionFontSize: 16px;
$descriptionMarginTop: 20px;

$statisticsMarginTop: 20px;
$statisticsPadding: 10px 0;

$statisticsItemValueFontSize: 22px;
$statisticsItemValueFontSizeMd: 20px;
$statisticsItemValueFontSizeXs: 22px;
$statisticsItemLabelFontSize: 18px;
$statisticsItemLabelFontSizeMd: 16px;
$statisticsItemLabelFontSizeXs: 16px;
$statisticsItemLabelMarginLeftXs: 10px;

$imageMarginTop: 20px;
$imageHeight: 305px;
$imageHeightXs: 235px;
$imageLabelFontSize: 19px;
$imageLabelPadding: 10px 15px;

$additionalInformationFontSize: 16px;
$additionalInformationMarginTop: 20px;

$additionalInformationProgressGraphMarginTop: 20px;

$investmentsFontSize: 16px;
$investmentsFontSizeLg: 14px;
$investmentsFontSizeMd: 16px;
$investmentsImgHeight: 18px;
$investmentsMarginTop: 20px;
$investmentsPadding: 20px 0 0;
$investmentsTablePaddingLeft: 5px;

.single-project {
    display: flex;
    flex-direction: column;
    margin-top: $singleProjectMarginTop;
    min-height: calc(100% - #{$singleProjectMarginTop});
    position: relative;

    .dg-info-box {
        right: -25px;
        top: 2px;

        .dg-info-box-content {
            position: absolute;
            bottom: 0;
        }

        @include media-breakpoint-down(sm) {
            .dg-info-box-content {
                right: 0;
                max-height: 40%;
                max-width: 80%;
                overflow: auto;
                position: fixed;
                bottom: 0;
                transform: translate(-50%, -50%);
            }
        }
    }

    &:hover {
        text-decoration: none;
    }

    @include media-breakpoint-down(md) {
        margin-top: $singleProjectMarginTopMd;
        min-height: calc(100% - #{$singleProjectMarginTopMd});
    }

    @include media-breakpoint-down(sm) {
        margin-top: $singleProjectMarginTopSm;
        min-height: calc(100% - #{$singleProjectMarginTopSm});
    }

    @include media-breakpoint-down(xs) {
        margin-top: $singleProjectMarginTopXs;
        min-height: calc(100% - #{$singleProjectMarginTopXs});
    }

    .title {
        -webkit-box-orient: vertical; // required for line-clamp
        box-orient: vertical; // required for line-clamp
        color: $dark;
        display: -webkit-box; // required for line-clamp
        font-family: $headerFont;
        font-size: $titleFontSize;
        font-weight: 700;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(xs) {
            font-size: $titleFontSizeXs;
        }
    }

    .location {
        font-family: $navigationFont;
        font-size: $locationFontSize;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .description {
        flex: 1;
        font-family: $navigationFont;
        font-size: $descriptionFontSize;
        margin-top: $descriptionMarginTop;

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .statistics {
        border-bottom: 2px solid $lighter;
        border-top: 2px solid $lighter;
        display: flex;
        justify-content: space-between;
        margin-top: $statisticsMarginTop;
        padding: $statisticsPadding;

        @include media-breakpoint-down(xs) {
            align-items: center;
            flex-direction: column;
        }

        .item {
            font-family: $headerFont;
            text-align: center;

            @include media-breakpoint-down(xs) {
                align-items: baseline;
                display: flex;
            }

            .value {
                color: $dark;
                font-size: $statisticsItemValueFontSize;
                white-space: nowrap;

                @include media-breakpoint-down(md) {
                    font-size: $statisticsItemValueFontSizeMd;
                }

                @include media-breakpoint-down(xs) {
                    font-size: $statisticsItemValueFontSizeXs;
                }
            }

            .label {
                color: $light;
                font-size: $statisticsItemLabelFontSize;
                position: relative;
                white-space: nowrap;

                @include media-breakpoint-down(md) {
                    font-size: $statisticsItemLabelFontSizeMd;
                }

                @include media-breakpoint-down(xs) {
                    font-size: $statisticsItemLabelFontSizeXs;
                    margin-left: $statisticsItemLabelMarginLeftXs;
                }

                .dg-info-box {
                    top: 0;
                }
            }
        }
    }

    .image {
        height: $imageHeight;
        margin-top: $imageMarginTop;
        overflow: hidden;
        position: relative;

        &.has-finum-bar {
            &::after {
                background-color: $primary;
                bottom: 0;
                content: '';
                display: block;
                height: 15px;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }

        &.has-safe-guard-program-seal {
            &::before {
                background-size: auto 100%;
                background-image: url('../images/seal-safe-guard-program.svg');
                background-repeat: no-repeat;
                bottom: 20px; // Keep some distance to the potential FiNUM bar, which is 15px in height
                content: '';
                display: block;
                height: 100px;
                left: 20px;
                position: absolute;
                width: 100px;
            }
        }

        @include media-breakpoint-down(sm) {
            &.has-safe-guard-program-seal {
                &::before {
                    height: 80px;
                    width: 80px;
                }
            }
        }

        @include media-breakpoint-down(xs) {
            height: $imageHeightXs;
        }

        img {
            display: block;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        .label {
            background-color: $secondary;
            color: $white;
            font-family: $headerFont;
            font-size: $imageLabelFontSize;
            left: 0;
            padding: $imageLabelPadding;
            position: absolute;
            top: 0;
            pointer-events: none;
            z-index: 1;

            &::before {
                background-color: $secondary;
                bottom: 0;
                content: '';
                display: block;
                pointer-events: none;
                position: absolute;
                right: -15px;
                top: 0;
                transform: skewX(-25deg);
                width: 50%;
            }

            .content {
                position: relative;
            }

            &.bottom {
                background-color: $white;
                border: 1px solid $secondary;
                bottom: 0;
                color: $primary;
                left: auto;
                right: 0;
                top: auto;

                &::before {
                    background-color: $white;
                    bottom: -1px;
                    border-bottom: 1px solid $secondary;
                    border-left: 1px solid $secondary;
                    border-top: 1px solid $secondary;
                    left: -15px;
                    right: auto;
                    top: -1px;
                }
            }

            &.additional {
                left: auto;
                right: 0;

                &::before {
                    left: -15px;
                    right: auto;
                    transform: skewX(25deg);
                }
            }

            &.primary {
                background-color: $primary;

                &::before {
                    background-color: $primary;
                }

                &.bottom {
                    background-color: $white;
                    border-color: $primary;

                    &::before {
                        background-color: $white;
                        border-bottom-color: $primary;
                        border-left-color: $primary;
                        border-top-color: $primary;
                    }
                }
            }

            &.green {
                background-color: $paidBackGreen;

                &::before {
                    background-color: $paidBackGreen;
                }

                &.bottom {
                    background-color: $white;
                    border-color: $paidBackGreen;

                    &::before {
                        background-color: $white;
                        border-bottom-color: $paidBackGreen;
                        border-left-color: $paidBackGreen;
                        border-top-color: $paidBackGreen;
                    }
                }
            }
        }
    }

    .additional-information {
        color: $black;
        font-family: $headerFont;
        font-size: $additionalInformationFontSize;
        margin-top: $additionalInformationMarginTop;

        table {
            width: 100%;
        }

        .progress-graph {
            margin-top: $additionalInformationProgressGraphMarginTop;
            text-align: center;

            @include media-breakpoint-down(lg) {
                margin-top: 0;
                text-align: right;
            }

            @include media-breakpoint-down(sm) {
                margin-top: $additionalInformationProgressGraphMarginTop;
                text-align: center;
            }
        }
    }

    .investments {
        border-top: 2px solid $lighter;
        color: $black;
        font-family: $headerFont;
        font-size: $investmentsFontSize;
        margin-top: $investmentsMarginTop;
        padding: $investmentsPadding;

        @include media-breakpoint-down(lg) {
            font-size: $investmentsFontSizeLg;
        }

        @include media-breakpoint-down(md) {
            font-size: $investmentsFontSizeMd;
        }

        table {
            tr {
                td {
                    vertical-align: top;

                    &:nth-child(2) {
                        padding-left: $investmentsTablePaddingLeft;
                    }
                }
            }

            &.countries {
                margin-left: auto;

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                    margin-top: 10px;
                }

                tr {
                    td {
                        border-left: 1px solid;
                        padding-left: 6px;
                        padding-right: 6px;

                        &:first-child {
                            border-left: 0;
                            padding-left: 0;
                        }

                        &:last-child {
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        img {
            height: $investmentsImgHeight;
        }
    }

    &.no-spacing {
        margin-top: 0;
        min-height: 100%;
    }

    &.display-as-card {
        background-color: $white;
        border: 2px solid $lighter;
        padding: $singleProjectCardPadding;
    }

    // FIXME: this is a quick hack to make it so the text on the project images
    // (that shouldn't even be there, as communicated) doesn't get cut off
    // at certain resolutions. This won't play nicely once there is more than
    // one ECSP pure project as the cards will have different heights, so a
    // better solution should be found.
    &.is-ecsp-pure {
        .image {
            height: auto !important;
        }
    }
}
