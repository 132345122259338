@each $color, $value in $theme-colors {
    .bg-#{$color} {
        background-color: $value;
    }
    .text-#{$color} {
        color: $value;
    }
    .border-#{$color} {
        border: 1px $value solid;
    }
}