$titleHeight: 200px;
$titleFontSize: 48px;
$titleFontSizeSm: 40px;
$titleFontSizeXs: 24px;
$titleFontWeightXs: 300;
$titlePaddingXs: 20px 0 5px;

$breadcrumbsMargin: 15px auto 0;
$breadcrumbsMarginXs: 10px auto 0;
$breadcrumbsFontSizeXs: 12px;

.subheader {
    @include section-spacing-bottom(true, true);

    &.disable-bottom-spacing {
        margin-bottom: 0;
    }

    .title {
        background-size: cover;
        background-image: url('../images/layout/title-bar-bg.jpg');
        background-position: left center;
        background-repeat: no-repeat;
        align-items: center;
        display: flex;
        height: $titleHeight;
        position: relative;
        z-index: 0;

        &::before {
            background-color: transparentize($secondary, 0.2);
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 10;

            @include media-breakpoint-down(xs) {
                content: none;
            }
        }

        h1 {
            color: $white;
            font-size: $titleFontSize;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            z-index: 20;

            @include media-breakpoint-down(sm) {
                font-size: $titleFontSizeSm;
            }

            @include media-breakpoint-down(xs) {
                color: $dark;
                font-size: $titleFontSizeXs;
                font-weight: $titleFontWeightXs;
            }
        }

        @include media-breakpoint-down(xs) {
            background-image: none;
            height: auto;
            overflow-x: hidden;
            padding: $titlePaddingXs;

            &::after {
                background-color: $secondary;
                bottom: 0;
                content: '';
                height: 2px;
                left: calc($grid-gutter-width / 2); // to align with the start of the column
                pointer-events: none;
                position: absolute;
                width: 999999px;
            }
        }
    }

    #breadcrumbs {
        font-family: $mainFont;
        margin: $breadcrumbsMargin;

        @include media-breakpoint-down(xs) {
            font-size: $breadcrumbsFontSizeXs;
            margin: $breadcrumbsMarginXs;
        }
    }
}
