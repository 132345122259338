.popover {
    max-width: 450px;

    .popover-body {
        ul {
            padding-inline-start: 20px;

            li{
                margin-left: 0;
            }
        }
    }
}