#jumbo {
    position: relative;
    height: 560px;
    width: 100%;
    background-position: center center;
    background-size: cover;

    @include media-breakpoint-down(lg) {
        height: 400px;
    }
    @include media-breakpoint-down(md) {
        height: 300px;
    }
}