$statisticsHomePadding: 140px 0 0;
$statisticsHomePaddingMd: 100px 0 0;
$statisticsHomePaddingXs: 80px 0 0;

.statistics-home {
    padding: $statisticsHomePadding;

    @include media-breakpoint-down(md) {
        padding: $statisticsHomePaddingMd;
    }

    @include media-breakpoint-down(xs) {
        padding: $statisticsHomePaddingXs;
    }
}
