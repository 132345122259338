#investContainer, #thankyouContainer, #investSelectContainer {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 0vh;
    background: $lightBlue;
    z-index: 11;
    overflow: hidden;
    transition: height 0.3s;

    .overlayBcgUberwisen {
        position: fixed;
        left: 0;
        top: 0px;
        width: 100%;
        height: 0vh;
        background: #000;
        z-index: 12;
        opacity: 0.5;
        overflow: hidden;
        transition: height 0.3s;

        &.show {
            height: 100vh;
        }
    }

    &.show {
        height: 100vh;
    }

    .investmentContainer {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        padding: 100px 175px;

        .pepGroup {
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s linear;

            &.show {
                max-height: 100%;
            }
        }

        .pepSelected {
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s linear;

            &.show {
                max-height: 150px;
            }

            i {
                font-size: 14px;
            }
        }

        .pepSpecific {
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s linear;

            &.show {
                max-height: 150px;
            }
        }

        .headerContainer {
            position: relative;
            background: $yellow;
            height: 160px;
            color: $lessDark;
            text-transform: uppercase;
            font-family: $font-alt;
            font-size: 30px;
            padding-top: 25px;
            line-height: 1;

            .closeIcon {
                cursor: pointer;
                position: absolute;
                display: block;
                width: 35px;
                height: 35px;
                line-height: 1;
                font-size: 35px;
                right: 20px;
                top: 25px;
                z-index: 12;
            }

            .title {
                font-size: 64px;
            }
        }

        .bodyContainer {
            background: white;
            padding: 0 15px 15px 15px;

            .errorFrame {
                background-color: rgba($color: $red, $alpha: 0.3);
                border-bottom: 1px $red solid;
                color: $red;
                font-weight: bold;
                min-height: 40px;
                line-height: 40px;
            }

            .infoFrame {
                background-color: rgba($color: $green, $alpha: 0.3);
                border-bottom: 1px $green solid;
                color: $green;
                font-weight: bold;
                min-height: 40px;
                padding: 10px;
            }

            .success {
                font-size: 36px;
                font-family: $navigationFont;
                color: $lessDark;
            }

            .projectName {
                margin: 20px 0;
                font-size: 32px;
                font-family: $font-alt;
                text-transform: uppercase;
                font-weight: 300;
            }

            table {
                width: 100%;
                font-family: $navigationFont;

                tr {
                    border-bottom: 1px $lighter solid;

                    &:first-child {
                        border-top: 1px $lighter solid;
                    }

                    td {
                        height: 55px;
                        padding-left: 15px;

                        &:first-child {
                            font-weight: 600;
                        }
                    }
                }
            }

            .subtitle {
                font-family: $navigationFont;
                font-size: 24px;
                margin-bottom: 15px;
                color: black;
            }

            .actionCode {
                border-radius: 0;
                border: 1px $lighter solid;
                height: 46px;
                max-width: 302px;
                margin-top: 30px;
            }

            .form-control {
                &.error {
                    border-color: $yellow;
                    color: $red;
                }


            }


            .highInvestmentCheckContainer {
                overflow: hidden;
                max-height: 0;
                transition: all 0.1s linear;

                &.show {
                    max-height: 100%;
                }
            }

            .hint {
                max-height: 0;
                overflow: hidden;
                font-size: 15px;
                color: $lessDark;
                background-color: $almostWhite;
                padding: 0;
                margin-bottom: 0px;
                transition: all 0.75s linear;

                b {
                    font-size: 17px;
                }

                &.error {
                    overflow: unset;
                    max-height: 100%;
                    padding: 15px;
                    position: relative;
                    border: 1px $yellow solid;
                    background-color: white;

                    &::before {
                        position: absolute;
                        display: block;
                        content: "";
                        top: -40px;
                        left: 0;
                        width: 36px;
                        height: 30px;
                        background: url("/images/icons/alert.svg") top left no-repeat;
                    }
                }

                &.show {
                    max-height: 100%;
                    padding: 15px;
                    margin-bottom: 50px;
                }
            }

            .form-check-label {
                margin-left: 20px;

                i {
                    margin-left: 15px;
                    color: $blue;
                    font-size: 20px;
                    line-height: 1;
                }
            }

            .invalid-feedback {
                margin-left: 20px;
            }

            .info {
                position: relative;
                border: 1px $blue solid;
                padding-left: 75px;
                padding: 20px 75px;
                font-size: 15px;

                &::before {
                    position: absolute;
                    display: block;
                    content: '\e017';
                    font-family: $dagobertFont;
                    color: $blue;
                    font-size: 40px;
                    line-height: 1;
                    left: 15px;
                    top: 20px;
                }
            }

            .document {
                position: relative;
                display: block;
                color: $lessDark;
                text-decoration: none;
                text-align: left;
                padding-left: 60px;
                height: 50px;
                line-height: 50px;
                margin-bottom: 40px;
                overflow: hidden;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '\e00b';
                    font-family: $dagobertFont;
                    font-size: 50px;
                    margin-right: 20px;
                    line-height: 1;
                }

                &:hover {
                    text-decoration: none;
                }
            }

            .invalid-feedback {
                display: block;
            }

            .expiriance2 {
                max-height: 0;
                overflow: hidden;
                transition: all 0.5s linear;

                &.show {
                    max-height: 200px;
                }
            }

            .expiriance3 {
                max-height: 0;
                overflow: hidden;
                transition: all 0.5s linear;

                &.show {
                    max-height: 500px;
                }
            }

            .expiriance4 {
                max-height: 0;
                overflow: hidden;
                transition: all 0.5s linear;

                &.show {
                    padding-top: 25px;
                    max-height: 500px;
                }
            }

            .form-group {
                position: relative;
            }

            .ibanValidation {
                position: absolute;
                top: 0;
                right: 25px;
                display: block;
                width: 15px;
                height: 34px;

                &.success::after {
                    content: '✓';
                    color: yellowgreen;
                    font-size: 20px;
                    display: block;
                    width: 15px;
                    height: 34px;
                    text-align: center;
                    line-height: 34px;
                    font-weight: bold;
                }

                &.error::after {
                    content: '✗';
                    color: red;
                    font-size: 20px;
                    display: block;
                    width: 15px;
                    height: 34px;
                    text-align: center;
                    line-height: 34px;
                    font-weight: bold;
                }

                &.loader::after {
                    content: '';
                    border: 2px solid #ffce01;
                    border-top: 2px solid #0f3540;
                    border-radius: 50%;
                    width: 15px;
                    height: 15px;
                    display: block;
                    margin-top: 9px;
                    animation: spin 2s linear infinite;
                }
            }

            .ibanCheck {
                max-height: 0;
                overflow: hidden;
                transition: all 0.75s linear;

                &.show {
                    max-height: 100%;
                }
            }
        }

        &.primary {
            .headerContainer {
                background: $primary;
                color: $white;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .investmentContainer {
            .bodyContainer {
                .info {
                    padding-right: 20px;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .investmentContainer {
            padding: 50px 50px;

            .bodyContainer {
                padding: 35px 0;

                .projectName {
                    margin: 40px 0;
                    font-size: 28px;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .investmentContainer {
            padding: 20px 30px;

            .headerContainer {
                height: 100px;
                font-size: 20px;
                padding-left: 15px;

                .closeIcon {
                    width: 20px;
                    height: 20px;
                    font-size: 20px;
                }

                .title {
                    font-size: 35px;
                }
            }

            .bodyContainer {
                padding: 15px;

                .projectName {
                    margin: 20px 0;
                    font-size: 26px;
                }
            }
        }
    }

    @include media-breakpoint-down(xs) {
        font-size: 14px;

        .investmentContainer {
            padding: 0;

            .bodyContainer {
                padding: 15px;

                .projectName {
                    font-size: 22px;
                }
            }
        }
    }
}

// New investment view (2023)

.investment-overlay {
    background-color: $white !important;
    color: $gray;
    font-family: $mainFont;
    font-size: 15px;

    &__header {
        background-color: $secondary;
        color: $white;
        line-height: 120px;
        height: 120px;
        padding-right: (40px - calc($grid-gutter-width / 2));

        &__title {
            font-family: $headerFont;
            font-size: 52px;
            text-transform: uppercase;
        }

        &__close {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: flex-end;

            button,
            a {
                @include input-reset;

                color: inherit;
                font-size: 24px;
                line-height: 1;
            }
        }
    }

    &__error {
        border: 1px solid $secondary;
        margin-top: 60px;
        padding: 25px 25px 25px 110px;
        position: relative;

        &::before {
            background-size: 100%;
            background-image: url('../images/icons/attention.svg');
            background-position: center;
            background-repeat: no-repeat;
            content: '';
            display: block;
            height: 100%;
            left: 25px;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 60px;
        }
    }

    &__project-information {
        &__title {
            color: $default;
            font-family: $headerFont;
            font-size: 38px;
            font-weight: 300;
            margin-top: 70px;
            text-transform: uppercase;
        }

        &__data {
            margin-top: 70px;

            table {
                table-layout: fixed;
                width: 100%;

                tr {
                    border-top: 1px solid #ccc;

                    &:last-child {
                        border-bottom: 1px solid #ccc;
                    }

                    td {
                        padding: 10px 0;
                        vertical-align: top;

                        &:first-child {
                            color: $lessDark;
                            font-weight: 500;
                        }
                    }
                }
            }

            .dg-info-box {
                right: unset;
                left: 105px;
                top: 10px;

                .dg-info-box-content {
                    position: absolute;
                    bottom: 0;
                }

                @include media-breakpoint-down(sm) {
                    .dg-info-box-content {
                        right: 0;
                        max-height: 40%;
                        max-width: 80%;
                        overflow: auto;
                        position: fixed;
                        bottom: 0;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        &__image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: calc(100% - 70px);
            margin-top: 70px;

            .label {
                background-color: $secondary;
                color: $white;
                font-family: $headerFont;
                font-size: $imageLabelFontSize;
                left: 15px;
                padding: $imageLabelPadding;
                position: absolute;
                top: 70px;
                pointer-events: none;
                z-index: 1;

                @include media-breakpoint-down(sm) {
                    top: 30px;
                }

                &::before {
                    background-color: $secondary;
                    bottom: 0;
                    content: '';
                    display: block;
                    pointer-events: none;
                    position: absolute;
                    right: -15px;
                    top: 0;
                    transform: skewX(-25deg);
                    width: 50%;
                }

                .content {
                    position: relative;
                }

                &.bottom {
                    background-color: $white;
                    border: 1px solid $secondary;
                    bottom: 0;
                    color: $primary;
                    left: auto;
                    right: 15px;
                    top: auto;

                    &::before {
                        background-color: $white;
                        bottom: -1px;
                        border-bottom: 1px solid $secondary;
                        border-left: 1px solid $secondary;
                        border-top: 1px solid $secondary;
                        left: -15px;
                        right: auto;
                        top: -1px;
                    }
                }

                &.additional {
                    left: auto;
                    right: 15px;

                    &::before {
                        left: -15px;
                        right: auto;
                        transform: skewX(25deg);
                    }
                }

                &.primary {
                    background-color: $primary;

                    &::before {
                        background-color: $primary;
                    }

                    &.bottom {
                        background-color: $white;
                        border-color: $primary;

                        &::before {
                            background-color: $white;
                            border-bottom-color: $primary;
                            border-left-color: $primary;
                            border-top-color: $primary;
                        }
                    }
                }

                &.green {
                    background-color: $paidBackGreen;

                    &::before {
                        background-color: $paidBackGreen;
                    }

                    &.bottom {
                        background-color: $white;
                        border-color: $paidBackGreen;

                        &::before {
                            background-color: $white;
                            border-bottom-color: $paidBackGreen;
                            border-left-color: $paidBackGreen;
                            border-top-color: $paidBackGreen;
                        }
                    }
                }
            }
        }
    }

    &__project-status {
        background-color: $almostWhite;
        margin-top: 50px;
        padding: 40px 0;

        &__campaign {
            align-items: center;
            color: $primary;
            display: flex;
            font-size: 41px;
            height: 100%;
            justify-content: center;
        }

        &__invested {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;

            &__content {
                line-height: 1;
                padding-left: 90px;
                position: relative;

                &::before {
                    background-size: 100%;
                    background-image: url('../images/icons/logo-bars-zuruckbezahlt.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    content: '';
                    display: block;
                    height: 100%;
                    left: 0;
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    width: 60px;
                }
            }

            &__value {
                color: $dark;
                font-family: $headerFont;
                font-size: 45px;
            }

            &__label {
                font-size: 20px;
                margin-top: 10px;
            }
        }
    }

    &__project-campaign {
        margin-top: 60px;

        &__description {
            text-align: center;

            strong {
                color: #3d3d3d;
            }
        }

        &__steps {
            border-bottom: 1px solid $secondary;
            border-top: 1px solid $secondary;
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            padding: 15px;
        }

        &__step {
            color: $lessDark;
            display: flex;
            font-weight: 500;

            &__value {
                padding-left: 60px;
                position: relative;

                &::before {
                    color: $secondary;
                    content: '\e042';
                    font-family: 'dagobertinvest';
                    font-size: 24px;
                    left: 0;
                    position: absolute;
                    right: 0;
                    text-align: center;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 60px;
                }
            }
        }
    }

    &__investment-blocked {
        margin-top: 60px;
        padding-bottom: 100px;
    }

    &__form {
        padding-bottom: 100px;
    }

    &__block {
        margin-top: 60px;

        &__title {
            border-bottom: 1px solid #ccc;
            color: $primary;
            font-size: 24px;
            padding-bottom: 5px;
        }

        &__info {
            margin-top: 20px;
        }

        &--investment-blocked {
            margin-top: 100px;
            padding-bottom: 100px;
        }

        &--investment {
            margin-top: 100px;
        }

        &__investment-blocked {
            margin-top: 40px;
        }

        &__investment-value {
            margin-top: 40px;

            .dg-input-wrapper {
                input[type='text'] {
                    padding-right: 120px !important;
                }

                > [class^='icon-'] {
                    cursor: pointer;
                    pointer-events: auto !important;

                    &.icon-plus {
                        border-left: 0;
                        right: 60px;
                    }
                }
            }

            &__change {
                margin-top: 20px;
            }
        }

        &__payment-breakdown {
            margin-top: 30px;

            background-color: $almostWhite;
            padding: 15px 40px;

            table {
                table-layout: fixed;
                width: 100%;

                tr {
                    border-bottom: 1px solid #d9d9d9;

                    th {
                        color: $default;
                        font-weight: 500;
                        padding: 10px 0;
                        vertical-align: bottom;

                        small {
                            font-size: 12px;
                            font-weight: 400;
                        }

                        > span {
                            display: inline-block;
                            position: relative;
                        }

                        .dg-info-box {
                            right: -25px;
                            top: -5px;
                        }
                    }

                    td {
                        text-align: right;
                        padding: 10px 0;
                        vertical-align: bottom;
                    }

                    &:last-of-type {
                        border-bottom: 0;

                        th,
                        td {
                            font-weight: 700;
                            padding-top: 23px;
                            position: relative;

                            &::before {
                                background-color: #d9d9d9;
                                content: '';
                                display: block;
                                height: 1px;
                                left: 0;
                                pointer-events: none;
                                position: absolute;
                                top: 2px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        &__hint {
            border: 1px solid transparent;
            background-color: $almostWhite;
            margin-bottom: 30px;
            margin-top: 40px;
            padding: 25px;

            &--important {
                background-color: transparent;
                border-color: $secondary;
            }
        }

        &__voucher-code {
            margin-top: 30px;

            .dg-input-wrapper {
                > [class^='icon-'] {
                    color: #29bd07;
                }
            }

            &__change {
                margin-top: 20px;
            }
        }

        &__voucher-code-message {
            border: 1px solid;
            margin-top: auto;
            padding: 25px;

            &--mobile {
                margin-top: 30px;
            }

            &--success {
                border-color: #29bd07;
                color: #29bd07;
            }

            &--error {
                border-color: #ff1400;
                color: #ff1400;
            }
        }

        &__payment-methods {
            .dg-input-wrapper {
                margin-top: 20px;

                label {
                    .dg-info-box {
                        right: -30px;
                        top: 0;
                    }
                }
            }

            &__change {
                margin-top: 20px;
            }
        }

        &__documents {
            border-bottom: 1px solid transparent; // Visual border comes from after element, this is to increase the height by 1px for even spacing
            display: flex;
            flex-wrap: wrap;
            margin-left: -80px;
            padding-bottom: 40px;
            position: relative;

            &::after {
                background-color: #ccc;
                bottom: 0;
                content: '';
                height: 1px;
                left: 80px;
                position: absolute;
                right: 0;
            }
        }

        &__document {
            margin-left: 80px;
            margin-top: 40px;

            a {
                align-items: end;
                color: inherit;
                display: inline-flex;
                position: relative;
                text-decoration: none;

                &::before {
                    content: '\e044';
                    font-family: $dagobertFont;
                    font-size: 200%;
                    line-height: 1;
                    margin-right: 10px;
                }

                span {
                    text-decoration: underline;
                }
            }

            &--hidden {
                display: none;
            }
        }
    }

    &__confirmation {
        padding-top: 20px;

        &__box {
            border: 1px solid $secondary;
            margin-top: 60px;
            padding: 25px;

            &--warning {
                padding-left: 110px;
                position: relative;

                &::before {
                    background-size: 100%;
                    background-image: url('../images/icons/attention.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    content: '';
                    display: block;
                    height: 100%;
                    left: 25px;
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    width: 60px;
                }
            }
        }
    }

    &__submit {
        margin-top: 50px;
        text-align: center;

        button {
            margin-top: 25px;
        }

        &__indicator {
            &__text {
                margin-top: 20px;
            }
        }
    }

    &__thank-you {
        padding-bottom: 100px;
        text-align: center;

        &__image {
            margin-top: 60px;

            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                width: 120px;
            }
        }

        &__title {
            color: $dark;
            font-family: $headerFont;
            font-size: 32px;
            margin-top: 60px;
        }

        &__text {
            p {
                margin: 20px 0 0 !important;
            }
            
            .small-font {
                font-size: 10px;
            }
        }

        &__project-name {
            font-family: $headerFont;
            font-size: 18px;
            margin-top: 60px;
            text-transform: uppercase;
        }

        &__project-image {
            margin-top: 20px;

            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                width: 250px;
            }
        }

        &__investment {
            margin-top: 20px;
        }

        &__other-projects-title {
            color: $dark;
            font-family: $headerFont;
            font-size: 24px;
            margin-top: 60px;
        }

        &__other-projects {
            margin-top: 40px;
        }

        &__other-project  {
            position: relative;

            &__name {
                font-family: $headerFont;
                font-size: 16px;
                overflow-x: hidden;
                text-overflow: ellipsis;
                text-transform: uppercase;
                white-space: nowrap;
            }

            &__image {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 200px;
                margin-top: 20px;
            }

            &__link {
                position: absolute;
                height: 100%;
                left: 0;
                text-indent: -999999px;
                top: 0;
                width: 100%;
                z-index: 10;
            }
        }

        &__close {
            margin-top: 20px;
        }

        &__store-link {
            display: inline-block;
            margin: 20px auto 0;

            img {
                display: block;
                height: auto;
                margin: 10px auto 0;
                max-width: 100%;
                width: 175px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: 13px !important;

        &__header {
            height: 55px;
            line-height: 55px;
            padding-right: 0;

            &__title {
                font-size: 22px;
            }

            &__close {
                button {
                    font-size: 12px;
                }
            }
        }

        &__project-information {
            &__title {
                font-size: 22px;
                margin-top: 30px;
            }

            &__image {
                height: 300px;
                margin-top: 30px;
            }

            &__data {
                font-size: 15px;
                margin-top: 40px;
            }
        }

        &__project-status {
            background-color: transparent;
            margin-top: 40px;
            padding: 0;

            &__invested {
                &__value {
                    font-size: 33px;
                }

                &__label {
                    font-size: 13px;
                }
            }

            &__campaign {
                background-color: $almostWhite;
                font-size: 39px;
                height: auto;
                margin-top: 40px;
                padding: 30px 40px;
            }
        }

        &__project-campaign {
            margin-top: 30px;

            &__steps {
                align-items: center;
                flex-direction: column;
                font-size: 15px;
                margin-top: 30px;
            }

            &__step {
                margin-top: 15px;
                width: 100%;

                &:first-child {
                    margin-top: 0;
                }

                &__label {
                    flex: 1;
                }
            }
        }

        &__block {
            &__title {
                font-size: 20px;
            }

            &--investment {
                margin-top: 60px;
            }

            &__investment-value {
                margin-top: 30px;
            }

            &__hint {
                margin-bottom: 0;
                margin-top: 30px;
            }

            &__payment-breakdown {
                font-size: 15px;

                tr {
                    td {
                        font-size: 13px;
                    }

                    &:last-child {
                        td {
                            font-size: 15px;
                        }
                    }
                }
            }

            &__documents {
                border-bottom: 0;
                display: block;
                margin-left: 0;
                padding-bottom: 0;

                &::after {
                    content: none;
                }
            }

            &__document {
                margin-left: 0;
                margin-top: 20px;
            }
        }

        &__confirmation {
            padding-top: 0;
        }

        &__submit {
            margin-top: 60px;

            &__payable-amount {
                font-size: 15px;
            }
        }

        &__thank-you {
            &__image {
                margin-top: 40px;

                img {
                    width: 50px;
                }
            }

            &__title {
                font-size: 18px;
                margin-top: 20px;
            }

            &__project-name {
                margin-top: 40px;
            }

            &__other-projects-title {
                font-size: 18px;
            }

            &__close {
                margin-top: 60px;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        &__project-information {
            &__image {
                height: 250px;
            }
        }
    }
}
