.btn {
    font-family: $navigationFont;
    font-weight: 400;
    font-size: 15px;
    position: relative;
    border-radius: 0;
    height: 42px;
    line-height: 40px;
    text-transform: uppercase;
    padding: 0 15px;
    text-align: left;
    -webkit-appearance: none;

    @include media-breakpoint-down(xs) {
        font-size: 12px;
    }

    &.disabled {
        opacity: 1;
        background-color: $default;
        &::after {
            content: 'COMING SOON';
            display: block;
            width: 130px;
            text-align: center;
            position: absolute;
            background-color: $yellow;
            border: 3px solid $blue;
            border-radius: 10px;
            font-size: 14px;
            line-height: 14px;
            padding: 10px;
            right: -15px;
            color: black;
            top: 5px;
            transform: rotate(30deg);
        }
    }

    &.big {
        width: 290px;
    }

    &.userProfile {
        height: 52px;
        line-height: 50px;
        font-size: 22px;
        margin-right: 10px;

        &.dashboard {
            padding-right: 75px;

            &::before {
                content: '\e00e';
                font-family: $dagobertFont;
                font-size: 34px;
                position: absolute;
                top: 0;
                right: 20px;
                width: 38px;
                height: 38px;
            }
        }

        &.mail {
            position: relative;
            padding-right: 75px;

            &::before {
                content: '\e00c';
                font-family: $dagobertFont;
                font-size: 38px;
                position: absolute;
                top: 0;
                right: 20px;
                width: 38px;
                height: 38px;
            }

            span {
                position: absolute;
                top: 2px;
                right: 5px;
                display: block;
                width: 25px;
                height: 25px;
                border-radius: 12px;
                background-color: $red;
                color: white;
                font-size: 15px;
                text-align: center;
                line-height: 25px;
                z-index: 1;
                font-family: $mainFont;
            }
        }

        &.hands {
            padding-right: 75px;

            &::before {
                content: '\e00f';
                font-family: $dagobertFont;
                font-size: 42px;
                position: absolute;
                top: 0;
                right: 20px;
                width: 38px;
                height: 38px;
            }
        }

        &.user {
            padding-right: 75px;

            &::before {
                content: '\e010';
                font-family: $dagobertFont;
                font-size: 30px;
                position: absolute;
                top: 0;
                right: 20px;
                width: 38px;
                height: 38px;
            }
        }

        &.sellBuy {
            padding-right: 75px;

            &::before {
                content: '\e018';
                font-family: $dagobertFont;
                font-size: 30px;
                position: absolute;
                top: 0;
                right: 20px;
                width: 38px;
                height: 38px;
            }
        }

        &.shareholderDashboard {
            padding-right: 75px;

            &::before {
                content: '\e01a';
                font-family: $dagobertFont;
                font-size: 30px;
                position: absolute;
                top: 0;
                right: 20px;
                width: 38px;
                height: 38px;
            }
        }

        &.overview {
            padding-right: 75px;
            margin-right: 0;

            &::before {
                content: '\e012';
                font-family: $dagobertFont;
                font-size: 30px;
                position: absolute;
                top: 0;
                right: 20px;
                width: 38px;
                height: 38px;
            }
        }

        &.document {
            padding-right: 75px;

            &::before {
                content: '\e00b';
                font-family: $dagobertFont;
                font-size: 30px;
                position: absolute;
                top: 0;
                right: 20px;
                width: 38px;
                height: 38px;
            }
        }

        @include media-breakpoint-down(lg) {
            height: 48px;
            line-height: 46px;
            font-size: 20px;
            margin-right: 10px;
        }

        @include media-breakpoint-down(md) {
            height: 85px;
            line-height: 1;
            font-size: 16px;
            margin-right: 10px;
            text-align: center;

            &.dashboard {
                padding: 0 5px;

                &::before {
                    font-size: 34px;
                    position: relative;
                    top: unset;
                    right: unset;
                    display: block;
                    width: 100%;
                    height: unset;
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }

            &.mail {
                padding: 0 5px;

                &::before {
                    font-size: 34px;
                    position: relative;
                    top: unset;
                    right: unset;
                    display: block;
                    width: 100%;
                    height: unset;
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }

                span {
                    right: 40px;
                    width: 18px;
                    height: 18px;
                    font-size: 11px;
                    line-height: 18px;
                    border-radius: 9px;
                }
            }

            &.hands {
                padding: 0 5px;

                &::before {
                    font-size: 34px;
                    position: relative;
                    top: unset;
                    right: unset;
                    display: block;
                    width: 100%;
                    height: unset;
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }

            &.user {
                padding: 0 5px;

                &::before {
                    font-size: 34px;
                    position: relative;
                    top: unset;
                    right: unset;
                    display: block;
                    width: 100%;
                    height: unset;
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }

            &.sellBuy {
                padding: 0 5px;

                &::before {
                    font-size: 34px;
                    position: relative;
                    top: unset;
                    right: unset;
                    display: block;
                    width: 100%;
                    height: unset;
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }

            &.shareholderDashboard {
                padding: 0 5px;

                &::before {
                    font-size: 34px;
                    position: relative;
                    top: unset;
                    right: unset;
                    display: block;
                    width: 100%;
                    height: unset;
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }

            &.overview {
                padding-right: 75px;
                height: 48px;
                line-height: 46px;
                font-size: 20px;
                margin-right: 10px;

                &::before {
                    content: '\e012';
                    font-family: $dagobertFont;
                    font-size: 30px;
                    position: absolute;
                    top: 0;
                    right: 20px;
                    width: 38px;
                    height: 38px;
                }
            }

            &.document {
                padding: 0 5px;

                &::before {
                    font-size: 34px;
                    position: relative;
                    top: unset;
                    right: unset;
                    display: block;
                    width: 100%;
                    height: unset;
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
        }

        @include media-breakpoint-down(xs) {
            height: 47px;
            width: 55px;

            &.mail {
                span {
                    right: 2px;
                }
            }

            &.overview {
                padding-right: 75px;
                height: 42px;
                width: unset;
                line-height: 40px;
                font-size: 18px;
                margin-right: 0;

                &::before {
                    content: '\e012';
                    font-family: $dagobertFont;
                    font-size: 30px;
                    position: absolute;
                    top: 0;
                    right: 20px;
                    width: 38px;
                    height: 38px;
                }
            }
        }
    }

    &.arrow {
        padding-right: 35px;

        &::after {
            content: '\e002';
            font-family: $dagobertFont;
            position: absolute;
            top: 0;
            right: 10px;
            width: 15px;
            height: 42px;
        }
    }

    &.arrowBack {
        padding-left: 35px;

        &::after {
            content: '\e002';
            font-family: $dagobertFont;
            position: absolute;
            top: 0;
            left: 10px;
            width: 15px;
            height: 39px;
            transform: rotate(180deg);
        }
    }

    &.double-arrow {
        padding-right: 35px;

        &::after {
            content: '\e01c';
            font-family: $dagobertFont;
            position: absolute;
            top: 0;
            right: 10px;
            width: 15px;
            height: 42px;
        }
    }

    &.info {
        padding-right: 35px;

        &::after {
            content: '\e01d';
            font-family: $dagobertFont;
            position: absolute;
            top: 0;
            right: 10px;
            width: 15px;
            height: 42px;
        }
    }

    &.small {
        height: 26px;
        line-height: 24px;
        font-size: 14px;
        padding: 0 15px;
        text-transform: lowercase;

        &.arrow {
            padding-right: 35px;

            &::after {
                content: '\e002';
                font-family: $dagobertFont;
                position: absolute;
                top: 0;
                right: 10px;
                width: 15px;
                height: 42px;
            }
        }

        &.double-arrow {
            padding-right: 35px;
        }
    }

    &.btn-light, &.btn-dark {
        height: 60px;
        line-height: 58px;
        font-size: 22px;
        text-align: center;
        color: black;

        @include media-breakpoint-down(xs) {
            width: 130px;
            height: unset;
            font-size: 16px;
            line-height: 1.2;
            padding: 10px;
        }
    }

    &.btn-light {
        background-color: white;
        border-color: white;
    }

    &.btn-dark {
        background-color: $lighter;
        border-color: $lighter;
    }

    &.lock {
        padding-right: 55px;

        &::after {
            content: '\e006';
            font-family: $dagobertFont;
            font-size: 20px;
            position: absolute;
            top: 0;
            right: 20px;
            width: 15px;
            height: 58px;
        }

        @include media-breakpoint-down(xs) {
            padding-left: 30px;
            padding-right: 30px;

            &::after {
                font-size: 14px;
                top: 29px;
                right: 20px;
                width: 15px;
                height: 58px;
            }
        }
    }

    &.scroll {
        padding-right: 55px;

        &::after {
            content: '\e00a';
            font-family: $dagobertFont;
            font-size: 18px;
            position: absolute;
            top: 0;
            right: 20px;
            width: 15px;
            height: 58px;
        }

        @include media-breakpoint-down(xs) {
            width: 100%;
            text-align: center;
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 68px;

            &::after {
                top: 28px;
                right: 0px;
                width: 100%;
                height: 58px;
                text-align: center;
            }
        }
    }

    &.btn-alt {
        border: 1px solid transparent;
        border-radius: 2px;
        box-shadow: 0 4px 10px 4px rgba($black, 0.1);
        font-family: $mainFont;
        font-size: 20px;
        height: auto;
        line-height: inherit;
        padding: 16px 30px;
        text-align: center;
        transition:
            background-color 150ms ease,
            border-color 150ms ease,
            color 150ms ease;
        width: auto !important;

        @include media-breakpoint-down(sm) {
            font-size: 18px;
        }

        @include media-breakpoint-down(xs) {
            padding: 16px 20px;
            font-size: 15px;
        }

        &.btn-primary {
            border-color: $white;
            color: $white;

            @media (hover: hover) {
                &:not([disabled]) {
                    &:hover {
                        background-color: $white;
                        border-color: $darker;
                        color: $darker;
                    }
                }
            }
        }

        &.btn-secondary {
            border-color: $secondary;
            color: $white;

            @media (hover: hover) {
                &:not([disabled]) {
                    &:hover {
                        background-color: $white;
                        color: $secondary;
                    }
                }
            }
        }

        &.btn-light {
            border-color: $darker;
            color: $darker;

            @media (hover: hover) {
                &:not([disabled]) {
                    &:hover {
                        background-color: $primary;
                        border-color: $white;
                        color: $white;
                    }
                }
            }
        }

        &.arrow,
        &.double-arrow,
        &.scroll,
        &.lock,
        &.info {
            padding-right: 70px;

            @include media-breakpoint-down(xs) {
                padding-right: 50px;
            }

            &::after {
                height: 30px;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                width: 15px;

                @include media-breakpoint-down(sm) {
                    height: 26px;
                }

                @include media-breakpoint-down(xs) {
                    height: 22px;
                    right: 20px;
                }
            }
        }

        &.big {
            width: auto;
        }
    }
}
