.responsive-video {
    overflow: hidden;
    height: 0;
    padding-bottom: 56%; /* 16/9 */
    position: relative;
    width: 100%;

    video,
    iframe,
    object,
    embed {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
