$singleClosedProjecttMarginTop: 120px;
$singleClosedProjecttMarginTopMd: 100px;
$singleClosedProjecttMarginTopSm: 80px;
$singleClosedProjecttMarginTopXs: 60px;

$titleFontSize: 30px;
$titleFontSizeXs: 24px;

$locationFontSize: 14px;

// The location is (temporarily?) not rendered, so we have to account for this
// here
// $topMinHeight: ($titleFontSize * 2 * 1.5) + ($locationFontSize * 1.5);
$topMinHeight: ($titleFontSize * 2 * 1.5);

$statisticsMarginTop: 20px;
$statisticsPadding: 10px 0;

$statisticsItemValueFontSize: 24px;
$statisticsItemValueFontSizeLg: 22px;
$statisticsItemValueFontSizeSm: 18px;
$statisticsItemLabelFontSize: 18px;
$statisticsItemLabelFontSizeLg: 16px;
$statisticsItemLabelFontSizeSm: 14px;

$imageMarginTop: 20px;
$imageHeight: 320px;
$imageHeightLg: 270px;
$imageHeightSm: 250px;
$imageHeightXs: 235px;
$imageLabelFontSize: 19px;
$imageLabelPadding: 10px 15px;

$additionalInformationFontSize: 16px;
$additionalInformationMarginTop: 20px;
$additionalInformationPaddingBottom: 20px;

.single-closed-project {
    display: flex;
    flex-direction: column;
    margin-top: $singleClosedProjecttMarginTop;
    min-height: calc(100% - #{$singleClosedProjecttMarginTop});
    overflow: hidden;
    position: relative;

    &:hover {
        text-decoration: none;
    }

    @include media-breakpoint-down(md) {
        margin-top: $singleClosedProjecttMarginTopMd;
        min-height: calc(100% - #{$singleClosedProjecttMarginTopMd});
    }

    @include media-breakpoint-down(sm) {
        margin-top: $singleClosedProjecttMarginTopSm;
        min-height: calc(100% - #{$singleClosedProjecttMarginTopSm});
    }

    @include media-breakpoint-down(xs) {
        margin-top: $singleClosedProjecttMarginTopXs;
        min-height: calc(100% - #{$singleClosedProjecttMarginTopXs});
    }

    .top {
        min-height: $topMinHeight;

        @include media-breakpoint-down(sm) {
            min-height: auto;
        }
    }

    .title {
        -webkit-box-orient: vertical; // required for line-clamp
        box-orient: vertical; // required for line-clamp
        color: $dark;
        display: -webkit-box; // required for line-clamp
        font-family: $headerFont;
        font-size: $titleFontSize;
        font-weight: 700;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(xs) {
            font-size: $titleFontSizeXs;
        }
    }

    .location {
        font-family: $navigationFont;
        font-size: $locationFontSize;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .statistics {
        border-bottom: 2px solid $lighter;
        border-top: 2px solid $lighter;
        display: flex;
        justify-content: space-between;
        margin-top: $statisticsMarginTop;
        padding: $statisticsPadding;

        .item {
            font-family: $headerFont;
            text-align: center;

            .value {
                color: $dark;
                font-size: $statisticsItemValueFontSize;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                @include media-breakpoint-down(lg) {
                    font-size: $statisticsItemValueFontSizeLg;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $statisticsItemValueFontSizeSm;
                }
            }

            .label {
                color: $light;
                font-size: $statisticsItemLabelFontSize;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                @include media-breakpoint-down(lg) {
                    font-size: $statisticsItemLabelFontSizeLg;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $statisticsItemLabelFontSizeSm;
                }
            }
        }
    }

    .image {
        height: $imageHeight;
        margin-top: $imageMarginTop;
        position: relative;

        &.has-finum-bar {
            &::after {
                background-color: $primary;
                bottom: 0;
                content: '';
                display: block;
                height: 15px;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }

        &.has-safe-guard-program-seal {
            &::before {
                background-size: auto 100%;
                background-image: url('../images/seal-safe-guard-program.svg');
                background-repeat: no-repeat;
                bottom: 20px; // Keep some distance to the potential FiNUM bar, which is 15px in height
                content: '';
                display: block;
                height: 100px;
                left: 20px;
                position: absolute;
                width: 100px;
            }
        }

        @include media-breakpoint-down(lg) {
            height: $imageHeightLg;
        }

        @include media-breakpoint-down(sm) {
            height: $imageHeightSm;

            &.has-safe-guard-program-seal {
                &::before {
                    height: 80px;
                    width: 80px;
                }
            }
        }

        @include media-breakpoint-down(xs) {
            height: $imageHeightXs;
        }

        img {
            display: block;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        .label {
            background-color: $secondary;
            color: $white;
            font-family: $headerFont;
            font-size: $imageLabelFontSize;
            left: 0;
            padding: $imageLabelPadding;
            position: absolute;
            top: 0;
            pointer-events: none;
            z-index: 1;

            &::before {
                background-color: $secondary;
                bottom: 0;
                content: '';
                display: block;
                pointer-events: none;
                position: absolute;
                right: -15px;
                top: 0;
                transform: skewX(-25deg);
                width: 50%;
            }

            .content {
                position: relative;
            }

            &.bottom {
                background-color: $white;
                border: 1px solid $secondary;
                bottom: 0;
                color: $primary;
                left: auto;
                right: 0;
                top: auto;

                &::before {
                    background-color: $white;
                    bottom: -1px;
                    border-bottom: 1px solid $secondary;
                    border-left: 1px solid $secondary;
                    border-top: 1px solid $secondary;
                    left: -15px;
                    right: auto;
                    top: -1px;
                }
            }

            &.additional {
                left: auto;
                right: 0;

                &::before {
                    left: -15px;
                    right: auto;
                    transform: skewX(25deg);
                }
            }

            &.primary {
                background-color: $primary;

                &::before {
                    background-color: $primary;
                }

                &.bottom {
                    background-color: $white;
                    border-color: $primary;

                    &::before {
                        background-color: $white;
                        border-bottom-color: $primary;
                        border-left-color: $primary;
                        border-top-color: $primary;
                    }
                }
            }

            &.green {
                background-color: $paidBackGreen;

                &::before {
                    background-color: $paidBackGreen;
                }

                &.bottom {
                    background-color: $white;
                    border-color: $paidBackGreen;

                    &::before {
                        background-color: $white;
                        border-bottom-color: $paidBackGreen;
                        border-left-color: $paidBackGreen;
                        border-top-color: $paidBackGreen;
                    }
                }
            }
        }
    }

    .additional-information {
        border-bottom: 2px solid $lighter;
        color: $black;
        font-family: $headerFont;
        font-size: $additionalInformationFontSize;
        margin-top: $additionalInformationMarginTop;
        padding-bottom: $additionalInformationPaddingBottom;

        table {
            width: 100%;

            tr {
                td {
                    &:nth-child(2) {
                        text-align: right;
                    }
                }
            }
        }
    }
}

.swiper {
    .single-closed-project {
        // We are displaying two projects per row in the slider on md-sized
        // screens, so the lines should still align in this special case
        .top {
            @include media-breakpoint-down(sm) {
                min-height: $topMinHeight;
            }

            @include media-breakpoint-down(xs) {
                min-height: auto;
            }
        }
    }
}
