header {
    background: $almostWhite;
    height: 150px;

    .seals {
        align-items: center;
        display: flex;
        gap: 10px;
        min-height: 100%;
    }

    img {
        max-width: 315px;
        height: auto;
        margin-top: 40px;

        &.seal {
            max-width: 100%;
            max-height: 85px;
        }
    }

    ul.nav {
        font-family: $navigationFont;
        font-size: 18px;
        margin-top: 55px;
        line-height: 23px;
        color: $darkBlue;

        .nav-link {
            padding: 24px 25px 10px 25px;

            @include media-breakpoint-down(lg) {
                padding: 24px 15px 10px 15px;
            }

            &.active {
                background-color: $yellow;
            }

            @media (hover: hover) {
                &:hover {
                    background-color: $yellow;
                }
            }
        }

        li {
            position: relative;
            background-color: transparent;
            transition: all 0.1s linear;

            &::first-line {
                font-weight: 700;
            }

            &:nth-child(1) {
                margin-left: 0;
                width: 122px;

                @include media-breakpoint-down(lg) {
                    width: 102px;
                }
            }
            &:nth-child(2) {
                width: 134px;

                @include media-breakpoint-down(lg) {
                    width: 114px;
                }
            }
            &:nth-child(3) {
                width: 172px;

                @include media-breakpoint-down(lg) {
                    width: 152px;
                }
            }
            &:nth-child(4) {
                width: 166px;

                @include media-breakpoint-down(lg) {
                    width: 146px;
                }
            }
            &:nth-child(5) {
                position: relative;
                width: 138px;

                @include media-breakpoint-down(lg) {
                    width: 118px;
                }

                i {
                    position: absolute;
                    font-size: 15px;
                    top: 24px;
                    right: 45px;
                }
            }

            .menuDropdown {
                position: absolute;
                left: 0;
                top: 95px;
                background-color: $almostWhite;
                border: 1px solid $lighter;
                z-index: -1;
                overflow: hidden;
                transition: opacity 0.2s linear 0.1s, z-index 0.2s linear 0.1s;
                opacity: 0;

                &.show {
                    z-index: 5;
                    opacity: 1;
                }

                a {
                    display: block;
                    margin-left: 15px;
                    padding-left: 10px;
                    height: 59px;
                    line-height: 59px;
                    overflow: hidden;
                    border-bottom: 1px solid $lighter;
                    color: $darkBlue;

                    @media (hover: hover) {
                        &:hover {
                            text-decoration: none;
                            background-color: $yellow;
                        }
                    }

                    &.active {
                        background-color: $yellow;
                    }

                    &:last-child {
                        border: 0;
                    }

                    span::before {
                        content: '(';
                    }
                    span::after {
                        content: ')';
                    }
                }
            }

            @media (max-width: 1540.98px) {
                &:last-child {
                    .menuDropdown {
                        left: auto;
                        right: -(calc($grid-gutter-width / 2));
                    }
                }
            }
        }
    }

    .hamburgerMenuIcon {
        position: relative;
        font-size: 44px;
        color: $darkBlue;
        cursor: pointer;

        i {
            opacity: 0;
            position: absolute;
            right: 0;
            top: 25px;
            transition: opacity 0.3s linear;

            &.show {
                opacity: 1;
            }
        }
    }

    @include media-breakpoint-down(md) {
        height: 120px;

        img {
            margin-top: 20px;
        }
    }

    @include media-breakpoint-down(xs) {
        height: 80px;

        img {
            margin-top: 15px;
            max-width: 200px;

            &.seal {
                max-height: 60px;
            }
        }

        .hamburgerMenuIcon {
            font-size: 33px;

            i {
                top: 14px;
            }
        }
    }
}

.hamburgerMenu {
    position: absolute;
    top: 120px;
    right: 0;
    display: block;
    height: calc(100vh - 120px);
    width: 0;
    background: $lightBlue;
    z-index: 1000;
    border-left: 0;
    padding-left: 0;
    overflow: hidden;
    transition: all 0.75s ease;

    &.show {
        width: 430px;
        padding-left: 30px;
        border-left: 1px solid $almostWhite;
    }

    .linkRow {
        position: relative;
        display: block;
        width: 400px;
        height: 80px;
        line-height: 80px;
        border-bottom: 1px solid white;
        font-family: $navigationFont;
        padding-left: 5px;
        font-size: 24px;

        .backHamburger {
            color: $darkBlue;
            cursor: pointer;

            &::after {
                position: absolute;
                content: '\e001';
                font-family: $dagobertFont;
                font-size: 30px;
                line-height: 80px;
                top: 0;
                right: 15px;
                transform: rotate(180deg);
            }
        }

        a {
            color: white;
            transition: color 0.3s linear;

            &.login {
                &::after {
                    content: '\e006';
                    font-family: $dagobertFont;
                    font-size: 15px;
                    position: absolute;
                    top: 1px;
                    left: 200px;
                }
            }

            &:hover {
                text-decoration: none;
                color: $yellow;
            }
        }

        a.moreHamburger {
            position: absolute;
            right: 0;
            top: 15px;
            display: block;
            width: 60px;
            height: 50px;
            border-left: 1px solid white;

            &::after {
                position: absolute;
                content: '\e001';
                font-family: $dagobertFont;
                display: block;
                width: 30px;
                height: 50px;
                font-size: 30px;
                line-height: 50px;
                top: 0;
                left: 15px;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        top: 80px;
        height: calc(100vh - 80px);
        border: 0;

        .linkRow {
            font-size: 18px;
            width: 310px;

            a {
                &.login {
                    &::after {
                        top: 0px;
                        left: 170px;
                    }
                }
            }
        }

        &.show {
            width: 320px;
            padding-left: 10px;
            border-left: 0;
        }
    }
}

html[lang="en"] {
    header {
        ul {
            &.nav {
                li {
                    .icon-lock {
                        display: none !important;
                    }
                }
            }
        }
    }

    .hamburgerMenu {
        .linkRow {
            a {
                &.login {
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }
}

html[lang="cs"],
html[lang="pl"] {
    header {
        ul {
            &.nav {
                font-size: 16px;

                li {
                    .icon-lock {
                        display: none !important;
                    }

                    &:first-child {
                        @include media-breakpoint-down(lg) {
                            width: 93px;
                        }
                    }

                    &:nth-child(3) {
                        width: 175px;

                        @include media-breakpoint-down(lg) {
                            width: 175px;
                        }
                    }
                }

                .nav-link {
                    padding: 24px 15px 10px;
                }
            }
        }
    }

    .hamburgerMenu {
        .linkRow {
            a {
                &.login {
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }
}
