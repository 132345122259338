#friendship {
    .bg-white {
        height: 100%;
        padding: 20px 25px
    }

    .title {
        text-transform: uppercase;
        font-family: "Exo2";
        font-size: 32px;
        font-weight: 300;
        color: $dark;
        margin-bottom: 30px;
    }

    .code-block { position: relative; border-radius: 0px; box-shadow: 0px 0px 4px #eee; }
    .code-block .code { border-radius: 0px; padding: 15px; font-size: 75%; background: #555; color: #eee; margin-bottom: .5rem; white-space: nowrap; overflow-x: auto; font-weight: bold; font-family: monospace; }
    .code-block > button.copy-to-clipboard { position: absolute; right: 1rem; top: 1rem; opacity: 0; transition: all .5s ease-out; font-size: 85%; border: none; border-radius: 0px; }
    .code-block:hover > button.copy-to-clipboard { opacity: 1; }

    table {
        width: 100%;
        font-family: $navigationFont;
        font-size: 14px;
        color: $lessDark;
        margin-top: 20px;

        tr {
            td {
                padding: 10px 5px;
                border-bottom: 1px $light solid;
                text-align: center;

                &:nth-child(1) {
                    text-align: left;
                }
            }
        }

        thead {
            tr {
                td {
                    font-size: 16px;
                    font-weight: 600;
                    vertical-align: bottom;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            font-size: 12px;

            thead {
                tr {
                    td {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    @import "friendship/dataOverview";
    @import "friendship/easyFriendship";
}