.overview {
    font-family: $headerFont;
    border: 2px $yellow solid;
    color: $dark;
    font-size: 16px;
    padding-bottom: 20px;

    .title {
        background-color: $yellow;
        color: $lessDark;
        text-align: center;
        line-height: 1;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 300;
        padding-top: 15px;
        padding-bottom: 7px;
        margin-bottom: 25px;
    }

    .dataContent  {
        padding: 0 10px;

        .bonusTitle {
            font-size: 20px;
            line-height: 30px;
        }

        .bonusValue {
            font-size: 22px;
            line-height: 30px;
        }

        hr {
            margin: 0;
            padding: 0;
            height: 1px;
            width: 100%;
            background: $lighter;
        }

        .value {
            font-size: 26px;
        }

        .valueLabel {
            font-size: 20px;
            color: $light;
        }

        .dataTable {
            line-height: 1.8;
        }
    }

    .dg-info-box {
        right: unset;
        left: 265px;
        top: 2px;

        .dg-info-box-content {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        @include media-breakpoint-down(sm) {
            left: 210px;
            top: 0px;

            .dg-info-box-content {
                right: 0;
                max-height: 40%;
                max-width: 80%;
                overflow: auto;
                position: fixed;
                bottom: 0;
                transform: translate(-50%, -50%);
            }
        }
    }

    @include media-breakpoint-only(lg) {
        font-size: 14px;

        .title {
            font-size: 20px;
        }

        .dataContent  {
            .bonusTitle {
                font-size: 17px;
                line-height: 25px;
            }

            .bonusValue {
                font-size: 20px;
                line-height: 25px;
            }

            .value {
                font-size: 18px;
            }

            .valueLabel {
                font-size: 16px;
            }

            .dataTable {
                line-height: 1.6;
            }
        }
    }

    @include media-breakpoint-only(md) {
        font-size: 12px;

        .title {
            font-size: 18px;
        }

        .dataContent  {
            .bonusTitle {
                font-size: 15px;
                line-height: 25px;
            }

            .bonusValue {
                font-size: 16px;
                line-height: 25px;
            }

            .value {
                font-size: 14px;
            }

            .valueLabel {
                font-size: 12px;
            }

            .dataTable {
                line-height: 1.6;
            }
        }
    }

    @include media-breakpoint-only(xs) {
        font-size: 12px;

        .title {
            font-size: 18px;
        }

        .dataContent  {
            .bonusTitle {
                font-size: 15px;
                line-height: 25px;
            }

            .bonusValue {
                font-size: 16px;
                line-height: 25px;
            }

            .value {
                font-size: 14px;
            }

            .valueLabel {
                font-size: 12px;
            }

            .dataTable {
                line-height: 1.6;
            }
        }
    }

    &.primary {
        border-color: $primary;

        .title {
            background-color: $primary;
            color: $white;
        }
    }
}
