#regheader {
    position: relative;
    background-color: $yellow;
    height: 190px;
    margin-bottom: 30px;

    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '\e005';
        font-family: $dagobertFont;
        color: $lessYellow;
        font-size: 170px;
        line-height: 1;
    }

    .container {
        height: 100%;

        .row {
            height: 100%;
        }
    }

    h1 {
        position: absolute;
        left: 15px;
        top: 50%;
        text-transform: uppercase;
        font-weight: 400;
        transform: translate(0, -50%);
        margin: 0px;
    }

    @include media-breakpoint-down(md) {
        height: 140px;

        &::before {
            font-size: 120px;
        }

        h1 {
            left: 150px;
        }
    }

    @include media-breakpoint-down(sm) {
        height: 95px;

        &::before {
            font-size: 84px;
        }
        h1 {
            left: 100px;
        }
    }

    @include media-breakpoint-down(xs) {
        height: 100px;
        &::before {
            content: unset;
        }
        h1 {
            left: 15px;
        }
    }
}