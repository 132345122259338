#rightCol {
    position: relative;

    .categories {
        position: relative;
        font-family: "Exo2";
        font-size: 20px;
        line-height: 28px;
        color: $lessDark;
        margin-bottom: 30px;
        padding-left: 60px;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 35px;
            height: 28px;
            content: '\e014';
            font-family: $dagobertFont;
            font-size: 35px;
            line-height: 28px;
            color: $light;
        }
    }

    #leftCol {
        position: absolute;
        top: -20px;
        left: -10px;
        display: none;
        width: 320px;
        height: unset;
        background-color: white;
        z-index: 1;
        border: 0;
        box-shadow: 3px 3px 3px $lightest;
        padding: 40px 30px;
        overflow: hidden;

        &.show {
            display: block;
        }

        .title {
            position: relative;
            border-bottom: 1px $light solid;
            padding-bottom: 20px;
            padding-left: 60px;

            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                display: block;
                width: 20px;
                height: 20px;
                font-size: 20px;
                color: $light;
                content: '\e013';
                font-family: $dagobertFont;
                transform: rotate(90deg);
            }
        }
    }

    .month {
        position: relative;
        display: block;
        width: 100%;
        height: 25px;
        background-color: $almostWhite;
        color: $default;
        text-align: right;
        line-height: 25px;
        padding-right: 35px;
        font-size: 13px;
        font-family: $font-alt;
        border-bottom: 1px $light solid;
        margin-top: 10px;

        .monthCheck {
            position: absolute;
            top: 2px;
            left: 10px;
        }

        span {
            &::after {
                content: '\e013';
                font-family: $dagobertFont;
                font-size: 14px;
                position: absolute;
                right: 10px;
                top: 0;
                transition: all 0.5s linear;
            }

            &.collapsed {
                &::after {
                    content: '\e013';
                    font-family: $dagobertFont;
                    font-size: 14px;
                    position: absolute;
                    right: 10px;
                    top: 0;
                    transform: rotate(90deg);
                }
            }
        }
    }

    table {
        width: 100%;
        font-family: $navigationFont;
        font-size: 14px;
        color: $lessDark;

        a {
            color: inherit;
        }

        tr {
            border-bottom: 1px $light solid;

            &:last-child {
                border-bottom: 0;
            }

            td {
                padding: 10px;
                
                text-align: left;

                &:nth-child(1) {
                    width: 33px;
                }

                &:nth-child(3) {
                    text-align: right;
                }

                .messageOpenLink {
                    cursor: pointer;
                }
            }
        }
    }

    .modal {
        background: rgba(0, 0, 0, 0.8);

        .modal-dialog {
            max-width: 1440px;

            .modal-content {
                height: calc(100vh - 3.5rem);

                .modal-header {
                    font-family: $navigationFont;
                    font-weight: 600;
                    font-size: 24px;
                    margin: 0 140px;
                    color: $lessDark;
                    padding: 20px 0 10px 0;
                    margin-bottom: 20px;
                }

                .modal-body {
                    padding: 0 300px;

                    iframe {
                        width: 100%;
                        height: calc(100% - 20px);
                        border: none;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .modal {
            .modal-dialog {
                max-width: 1100px;

                .modal-content {
                    .modal-header {
                        margin: 0 20px;
                    }

                    .modal-body {
                        padding: 0 100px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .modal {
            .modal-dialog {
                max-width: 650px;

                .modal-content {
                    .modal-header {
                        margin: 0 10px;
                    }

                    .modal-body {
                        padding: 0 10px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .modal {
            .modal-dialog {
                .modal-content {
                    .modal-header {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}