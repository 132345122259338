$itemMarginTopXs: $grid-gutter-width;

$itemIconFontSize: 80px;
$itemIconFontSizeMd: 70px;
$itemIconFontSizeSm: 60px;
$itemIconFontSizeXs: 50px;
$itemLabelFontSize: 21px;
$itemLabelFontSizeMd: 19px;
$itemLabelFontSizeSm: 14px;
$itemLabelFontSizeXs: 11px;
$itemLabelMarginTop: 10px;
$itemLabelMarginTopSm: 5px;
$itemLabelPaddingTop: 10px;
$itemValueFontSize: 28px;
$itemValueFontSizeMd: 26px;
$itemValueFontSizeSm: 24px;
$itemValueFontSizeXs: 22px;

.statistics-counter {
    &.negate-container-spacing {
        margin-left: -(calc($grid-gutter-width / 2));
        margin-right: -(calc($grid-gutter-width / 2));
    }

    .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media-breakpoint-down(xs) {
            margin-top: -$itemMarginTopXs;
        }

        .item {
            text-align: center;
            display: flex;

            @include media-breakpoint-down(sm) {
                display: block;
                margin-top: $itemMarginTopXs;
                width: 50%;
            }

            .icon {
                color: $secondary;
                font-size: $itemIconFontSize;
                line-height: 1;
                margin-right: 15px;

                @include media-breakpoint-down(md) {
                    font-size: $itemIconFontSizeMd;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $itemIconFontSizeSm;
                    margin-right: 10px;
                }

                @include media-breakpoint-down(xs) {
                    font-size: $itemIconFontSizeXs;
                    margin-right: 0;
                }
            }

            .value {
                color: $dark;
                font-family: $headerFont;
                font-size: $itemValueFontSize;
                font-weight: 700;

                @include media-breakpoint-down(md) {
                    font-size: $itemValueFontSizeMd;
                }

                @media (max-width: 1040px) {
                    font-size: $itemValueFontSizeSm;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $itemValueFontSizeSm;
                }

                @include media-breakpoint-down(xs) {
                    font-size: $itemValueFontSizeXs;
                }
            }

            .label {
                color: $dark;
                font-family: $navigationFont;
                font-size: $itemLabelFontSize;
                margin-top: $itemLabelMarginTop;
                padding-top: $itemLabelPaddingTop;
                position: relative;

                @include media-breakpoint-down(md) {
                    font-size: $itemLabelFontSizeMd;
                }

                @media (max-width: 1040px) {
                    font-size: $itemLabelFontSizeSm;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $itemLabelFontSizeSm;
                    margin-top: $itemLabelMarginTopSm;
                }

                @include media-breakpoint-down(xs) {
                    font-size: $itemLabelFontSizeXs;
                }

                &::before {
                    background-color: $dark;
                    content: '';
                    display: block;
                    height: 1px;
                    left: 50%;
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    transform: translateX(-50%);
                    width: 80%;
                }
            }

            &:nth-child(even) {
                .icon {
                    color: $primary;
                }
            }
        }
    }
}
