#breadcrumbs {
    font-size: 16px;
    margin-top: 30px;
    
    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
}