table {
    width: 100%;
    font-family: $navigationFont;
    font-size: 14px;
    color: $lessDark;
    margin-top: 20px;

    tr {
        td {
            padding: 10px 10px;
            border-bottom: 1px $light solid;
            text-align: center;

            &:nth-child(1) {
                text-align: left;
            }

            a.sort {
                position: relative;
                color: $lessDark;

                &::before {
                    position: absolute;
                    right: -13px;
                    top: 0;
                    content: '\e002';
                    font-family: $dagobertFont;
                    display: block;
                    color: $light;
                    font-size: 12px;
                    line-height: 24px;
                    transform: rotate(90deg);
                    text-align: center;
                }

                &::after {
                    position: absolute;
                    right: -20px;
                    top: 0;
                    content: '\e002';
                    font-family: $dagobertFont;
                    display: block;
                    color: $light;
                    font-size: 12px;
                    line-height: 24px;
                    transform: rotate(-90deg);
                    text-align: center;
                }

                &.ASC {
                    &::before {
                        color: $lessDark;
                    }
                }

                &.DESC {
                    &::after {
                        color: $lessDark;
                    }
                }
            }

            &.subTable {
                border: 0;
                padding: 0;
            }

            &.details {
                padding: 0;
                border: 0;

            }

            .btn.small {
                text-transform: capitalize;
            }

            .detail {
                padding: 30px;
                border: 1px $light solid;
                border-top: 0;
                font-size: 18px;

                h3 {
                    border-bottom: 1px $light solid;
                    font-size: 26px;
                }

                a.document {
                    align-items: center;
                    color: $lessDark;
                    display: flex;
                    min-height: 75px;
                    padding-left: 60px;
                    position: relative;
                    text-align: left;
                    text-decoration: none;
                    width: 100%;

                    &::before {
                        content: '\e00b';
                        font-family: $dagobertFont;
                        font-size: 50px;
                        left: 0;
                        position: absolute;
                        top: 0;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                .has-info-box {
                    position: relative;

                    .dg-info-box {
                        font-weight: initial;
                        right: -25px;
                        top: 0;
                    }
                }
            }
        }
    }

    thead {
        tr {
            td {
                font-size: 16px;
                font-weight: 600;
                vertical-align: bottom;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: 12px;

        thead {
            tr {
                td {
                    font-size: 14px;
                }
            }
        }
    }

    @include media-breakpoint-down(xs) {
        tr {
            td {
                .detail {
                    padding: 10px;
                    font-size: 14px;

                    h3 {
                        font-size: 18px;
                    }

                    a.document {
                        min-height: 30px;
                        padding-left: 30px;

                        &::before {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}
