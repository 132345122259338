#portfolioChart {
    .bg-white {
        height: 100%;
        padding: 20px 25px
    }

    .title {
        text-transform: uppercase;
        font-family: $font-alt;
        font-size: 32px;
        font-weight: 300;
        color: $dark;
        margin-bottom: 10px;
    }

    .data {
        color: $lessDark;
        margin-bottom: 30px;

        span {
            display: inline-block;
            padding: 0 15px;

            i {
                font-style: normal;
                font-family: $navigationFont;
                font-size: 22px;
            }

            &:first-child {
                border-right: 1px $light solid;
            }
        }
    }

    .donutChart {
        display: inline-block;
        position: relative;
        width: 310px;
        height: 310px;
    }
    .center {
        background: white;
        position: absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        width: 220px;
        height: 220px;
        margin: auto;
        border-radius: 50%;
    }
    .innerShadow {
        background: transparent;
        position: absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        width: 240px;
        height: 240px;
        margin: auto;
        border-radius: 50%;
        border: 10px solid rgba(0,0,0,.23);
    }
    .outerShadow {
        background: transparent;
        position: absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        width: 300px;
        height: 300px;
        margin: auto;
        border-radius: 50%;
        border: 3px solid rgba(255,255,200,.5);
    }
    .portionBlock {
        border-radius: 50%;
        clip: rect(0px, 310px, 310px, 155px);
        height: 100%;
        position: absolute;
        width: 100%;
    }
    .circle {
        border-radius: 50%;
        clip: rect(0px, 155px, 310px, 0px);
        height: 100%;
        position: absolute;
        width: 100%;
    }

    table {
        width: 100%;
        font-family: $navigationFont;
        font-size: 14px;
        color: $lessDark;
        margin-top: 20px;

        tr {
            td {
                padding: 10px 0;
                border-bottom: 1px $light solid;
                text-align: center;

                &:first-child {
                    border-bottom: 0;
                }

                &:nth-child(2) {
                    text-align: left;
                }
            }

            &.sum {
                font-weight: 600;
            }
        }

        thead {
            tr {
                td {
                    font-size: 16px;
                    font-weight: 600;
                    vertical-align: bottom;
                }
            }
        }

        .color {
            display: block;
            width: 25px;
            height: 25px;
        }

        &.mobileTable {
            tr {
                td {
                    &:first-child {
                        border-bottom: 1px $light solid;
                    }

                    &:nth-child(2) {
                        text-align: center;
                    }
                }
            }
        }
    }

    .portfolio-description {
        margin-top: 30px;

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .text {
        margin-top: 35px;
        font-family: $navigationFont;
        font-size: 14px;
        color: $lessDark;
    }

    @include media-breakpoint-only(md) {
        .donutChart {
            width: 210px;
            height: 210px;
        }
        .center {
            width: 130px;
            height: 130px;
        }
        .innerShadow {
            width: 150px;
            height: 150px;
        }
        .outerShadow {
            width: 200px;
            height: 200px;
        }
        .portionBlock {
            clip: rect(0px, 210px, 210px, 105px);
        }
        .circle {
            clip: rect(0px, 105px, 210px, 0px);
        }
    }

    @include media-breakpoint-only(xs) {
        .donutChart {
            width: 160px;
            height: 160px;
        }
        .center {
            width: 100px;
            height: 100px;
        }
        .innerShadow {
            width: 120px;
            height: 120px;
        }
        .outerShadow {
            width: 150px;
            height: 150px;
        }
        .portionBlock {
            clip: rect(0px, 160px, 160px, 80px);
        }
        .circle {
            clip: rect(0px, 80px, 160px, 0px);
        }
    }
}
