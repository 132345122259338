$videoWidth: 300px;

// $videoAspectRatio: 0.5625; /* 16:9 */
$videoAspectRatio: 0.42857143; // Aspect ratio without black borders

#video-preview {
    background-color: $black;
    bottom: 10px;
    box-shadow: 1px 3px 4px rgba($black, 0.1);
    height: $videoWidth * $videoAspectRatio;
    position: fixed;
    right: 10px;
    width: 300px;
    z-index: 10;

    .video-preview-close {
        @include input-reset;

        background-color: $primary;
        color: $white;
        height: 30px;
        line-height: 30px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 30px;
        z-index: 1;
    }

    video {
        cursor: pointer;
        height: 100%;
        // pointer-events: none;
        position: absolute;
        width: 100%;
    }
}
