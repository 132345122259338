//
// Color system
//

$default: #595959;
$black: #000000;
$darker: #00203D;
$dark: #303030;
$lessDark: #3C3C3C;
$darkGray: #404040;
$gray: #737373;
$accentGray: #999;
$light: #B0B0B0;
$white: #FFFFFF;
$offWhite: #fafafa;
$almostWhite: #F5F5F5;
$lighter: #CDCDCD;
$lightest: #E1E1E1;

$yellow: #FAC000;
$lessYellow: #FFEEA6;
$blue: #004460;
$darkBlue: #0F3540;
$mediumBlue: #006F9B;
$lightBlue: #639EBF;
$ultraLightBlue: #C9DDE8;
$green: #678615;
$softGreen: #a6b970;
$paidBackGreen: #88ae42;
$red: #FF1D25;
$softRed: #ff7176;

$primary: $blue;
$secondary: $yellow;

$theme-colors: (
    "yellow":           $yellow,
    "lessYellow":       $lessYellow,
    "blue":             $blue,
    "darkBlue":         $darkBlue,
    "mediumBlue":       $mediumBlue,
    "lightBlue":        $lightBlue,
    "ultraLightBlue":   $ultraLightBlue,
    "green":            $green,
    "softGreen":        $softGreen,
    "red":              $red,
    "softRed":          $softRed,
    "default":          $default,
    "dark":             $dark,
    "lessDark":         $lessDark,
    "light":            $light,
    "almostWhite":      $almostWhite,
    "lighter":          $lighter,
    "lightest":         $lightest
);


//
// Typography
//

$mainFont: "OpenSans";
$headerFont: "Exo2";
$navigationFont: "TitilliumWeb";
$dagobertFont: "dagobertinvest";

// Body
$body-color: $default;
$body-bg: white;
$font-primary: $mainFont;
$font-alt: $headerFont;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 650px,
    md: 940px,
    lg: 1160px,
    xl: 1541px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    xl: 1890px
);

$grid-gutter-width: 30px;

// Cookie consent
:root {
    --cc-border-radius: 0 !important;
    --cc-btn-border-radius: 0 !important;
    --cc-btn-primary-bg: #{$primary} !important;
    --cc-btn-primary-text: #{$white} !important;
    --cc-btn-primary-hover-bg: #{$white} !important;
    --cc-btn-primary-hover-text: #{$primary} !important;
    --cc-font-family: #{$mainFont} !important;
    --cc-text: #{$default} !important;
}
