.projectProgress {
    .investmentsText {
        font-family: "Exo2";
        fill: $blue;
        font-weight: bold;
    }

    .startFinishLine {
        fill: $blue;
    }
}