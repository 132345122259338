.spinner {
    @include section-spacing(true, false);
    height: 40px;
    margin-left: auto !important;
    margin-right: auto !important;
    position: relative;
    width: 40px;

    &::before,
    &::after {
        animation: spinner-bounce 2.0s infinite ease-in-out;
        background-color: $secondary;
        border-radius: 50%;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.6;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &::after {
        animation-delay: -1.0s;
    }
}

@-webkit-keyframes spinner-bounce {
    0%, 100% {
        -webkit-transform: scale(0.0);
    }

    50% {
        -webkit-transform: scale(1.0);
    }
}

@keyframes spinner-bounce {
    0%, 100% {
        -webkit-transform: scale(0.0);
        transform: scale(0.0);
    }

    50% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}
