@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans/opensans-extrabold.woff2') format('woff2'), 
         url('../../fonts/opensans/opensans-extrabold.woff') format('woff'), 
         url('../../fonts/opensans/opensans-extrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans/opensans-extrabolditalic.woff2') format('woff2'), 
         url('../../fonts/opensans/opensans-extrabolditalic.woff') format('woff'), 
         url('../../fonts/opensans/opensans-extrabolditalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans/opensans-bold.woff2') format('woff2'), 
         url('../../fonts/opensans/opensans-bold.woff') format('woff'), 
         url('../../fonts/opensans/opensans-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans/opensans-bolditalic.woff2') format('woff2'), 
         url('../../fonts/opensans/opensans-bolditalic.woff') format('woff'), 
         url('../../fonts/opensans/opensans-bolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans/opensans-semibold.woff2') format('woff2'), 
         url('../../fonts/opensans/opensans-semibold.woff') format('woff'), 
         url('../../fonts/opensans/opensans-semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans/opensans-semibolditalic.woff2') format('woff2'), 
         url('../../fonts/opensans/opensans-semibolditalic.woff') format('woff'), 
         url('../../fonts/opensans/opensans-semibolditalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans/opensans-regular.woff2') format('woff2'), 
         url('../../fonts/opensans/opensans-regular.woff') format('woff'), 
         url('../../fonts/opensans/opensans-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans/opensans-italic.woff2') format('woff2'), 
         url('../../fonts/opensans/opensans-italic.woff') format('woff'), 
         url('../../fonts/opensans/opensans-italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans/opensans-light.woff2') format('woff2'), 
         url('../../fonts/opensans/opensans-light.woff') format('woff'), 
         url('../../fonts/opensans/opensans-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans/opensans-lightitalic.woff2') format('woff2'), 
         url('../../fonts/opensans/opensans-lightitalic.woff') format('woff'), 
         url('../../fonts/opensans/opensans-lightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}