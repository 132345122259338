#texts {
    .blueTitle {
        display: inline-block;
        background-color: $lightBlue;
        height: 43px;
        min-width: 330px;
        line-height: 43px;
        color: white;
        font-family: $navigationFont;
        text-transform: uppercase;
        padding: 0 20px;
        margin-bottom: 25px;
    }

    h3 {
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 300;
        margin-bottom: 50px;
    }

    @include media-breakpoint-down(sm) {
        .blueTitle {
            height: unset;
            min-width: unset;
            width: 100%;
        }
    }

    @include media-breakpoint-down(xs) { 
        h3 {
            text-align: left;
        }
        
        .text {
            text-align: left;
        }
    }
}