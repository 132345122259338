#counter {
    font-family: $navigationFont;
    line-height: 0.6;
    color: $dark;

    .frame {
        clear: both;
        img {
            float: left;
            width: 59px;
            height: auto;
            margin-right: 45px;
        }

        .values {
            text-align: center;
            float: left;
            font-size: 42px;
            font-weight: 700;

            .text {
                margin-top: 31px;
                font-weight: 400;
                font-size: 21px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .frame {
            img {
                width: 45px;
                margin-right: 30px;
            }
    
            .values {
                font-size: 30px;
    
                .text {
                    font-size: 15px;
                    margin-top: 26px;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .frame {
            display: inline-block;

            img {
                margin-right: 35px;
            }

            &.first {
                width: 59%;
                margin-bottom: 50px;
            }
            &.second {
                width: 40%;
                margin-bottom: 50px;
            }
            &.third {
                width: 59%;
            }
            &.fourth {
                width: 40%;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .frame {
            text-align: center;

            img {
                margin-right: 0px;
                float: unset;
                margin-bottom: 15px;
            }

            .values {
                float: unset;
                line-height: 1;
                font-size: 24px;

                .text {
                    margin-top: 5px;
                }
            }

            &.first {
                margin-bottom: 30px;
                width: 49%;
            }
            &.second {
                margin-bottom: 30px;
                width: 49%;
            }
            &.third {
                width: 49%;
            }
            &.fourth {
                width: 49%;
            }
        }
    }
}