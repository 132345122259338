#video {
    margin-bottom: 115px;

    iframe {
        width: 100%;
        height: 513px;
    }

    .quote {
        position: absolute;
        bottom: 75px;
        font-family: $font-alt;
        font-weight: 300;
        font-style: italic;
        font-size: 28px;
    }

    @include media-breakpoint-down(lg) {
        iframe {
            height: 510px;
        }
    }

    @include media-breakpoint-down(md) {
        iframe {
            height: 380px;
        }

        .quote {
            font-size: 26px;
        }
    }

    @include media-breakpoint-down(sm) {
        iframe {
            height: 400px;
        }

        .quote {
            position: relative;
            bottom: unset;
            font-size: 24px;
            margin-top: 35px;
        }
    }

    @include media-breakpoint-down(xs) {
        iframe {
            height: 220px;
        }

        .quote {
            font-size: 18px;
            margin-top: 15px;
        }
    }
}
