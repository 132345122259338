@import "dashboard/cancelInvestment";
#dashboard {
    .bg-white {
        height: 100%;
        padding: 20px 25px
    }

    .title {
        text-transform: uppercase;
        font-family: "Exo2";
        font-size: 32px;
        font-weight: 300;
        color: $dark;
        margin-bottom: 10px;
    }

    .noData {
        text-align: center;
        background-color: $almostWhite;
        border: 1px solid $lighter;
        border-radius: 5px;
        font-size: 28px;
        font-family: $navigationFont;
        font-weight: 300;

        @include media-breakpoint-down(xs) {
            font-size: 18px;
        }
    }

    table {
        width: 100%;
        font-family: $navigationFont;
        font-size: 14px;
        color: $lessDark;
        margin-top: 20px;

        tr {
            td {
                padding: 10px 5px;
                border-bottom: 1px $light solid;
                text-align: center;

                &:nth-child(1) {
                    text-align: left;
                }
            }
        }

        thead {
            tr {
                td {
                    font-size: 16px;
                    font-weight: 600;
                    vertical-align: bottom;
                }
            }
        }

        .btn {
            text-transform: uppercase;
        }

        @include media-breakpoint-down(sm) {
            font-size: 12px;

            thead {
                tr {
                    td {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .performance-calculation-info {
        font-family: $navigationFont;
        font-size: 14px;
    }

    @import "dashboard/currentInvestmentOpportunities";
    @import "dashboard/nextPayment";
    @import "dashboard/lastInvestments";
    @import "dashboard/lastPayments";
}
