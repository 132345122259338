$padding: 60px 0;

$itemPadding: 25px 60px;
$itemPaddingSm: 30px 0;

$itemQuotationMarkSize: 50px;
$itemQuotationMarkSizeSm: 20px;
$itemTextFontSize: 18px;
$itemTextFontSizeMd: 14px;
$itemTextFontSizeSm: 12px;
$itemTextMargin: 40px 0;
$itemTextMarginSm: 30px 0 0;
$itemRatingHeight: 30px;
$itemRatingHeightSm: 18px;
$itemRatingMarginTopSm: 20px;
$itemAuthorFontSize: 28px;
$itemAuthorFontSizeMd: 22px;
$itemAuthorFontSizeSm: 16px;

$swiperButtonWidth: $grid-gutter-width * 2;

.testimonials {
    @include section-spacing(true, false);
    background-color: $almostWhite;

    &.no-spacing {
        margin-top: 0;
    }

    &.negate-container-spacing {
        margin-left: -(calc($grid-gutter-width / 2));
        margin-right: -(calc($grid-gutter-width / 2));
    }

    .items {
        padding: $padding;

        .item {
            padding: $itemPadding;
            position: relative;

            @include media-breakpoint-down(sm) {
                padding: $itemPaddingSm;
            }

            &::before,
            &::after {
                background-image: url('../images/icons/quotation-mark.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                content: '';
                display: block;
                height: $itemQuotationMarkSize;
                position: absolute;
                width: $itemQuotationMarkSize;

                @include media-breakpoint-down(sm) {
                    height: $itemQuotationMarkSizeSm;
                    width: $itemQuotationMarkSizeSm;
                }
            }

            &::before {
                bottom: 0;
                left: 0;

                @include media-breakpoint-down(sm) {
                    left: auto;
                    right: 0;
                }
            }

            &::after {
                right: 0;
                top: 0;

                @include media-breakpoint-down(sm) {
                    left: 0;
                    right: auto;
                }
            }

            .photo {
                img {
                    border-radius: 50%;
                    display: block;
                    width: 100%;
                }
            }

            .text {
                font-family: $mainFont;
                font-size: $itemTextFontSize;
                line-height: 1.67;
                margin: $itemTextMargin !important;
                text-align: left !important;

                @include media-breakpoint-down(md) {
                    font-size: $itemTextFontSizeMd;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $itemTextFontSizeSm;
                    margin: $itemTextMarginSm !important;
                }
            }

            .rating {
                @include media-breakpoint-down(sm) {
                    margin-top: $itemRatingMarginTopSm;
                }

                img {
                    display: block;
                    height: $itemRatingHeight;

                    @include media-breakpoint-down(sm) {
                        height: $itemRatingHeightSm;
                    }
                }
            }

            .author {
                font-size: $itemAuthorFontSize;
                font-style: italic;
                font-weight: 500;
                line-height: 1.25;

                @include media-breakpoint-down(md) {
                    font-size: $itemAuthorFontSizeMd;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $itemAuthorFontSizeSm;
                }
            }
        }

        .swiper-button {
            @include input-reset;
            color: $light;
            cursor: pointer;
            font-size: 2em;
            height: 100%;
            position: absolute;
            top: 0;
            width: $swiperButtonWidth;
            z-index: 10;

            @include media-breakpoint-down(sm) {
                display: none !important;
            }

            &.swiper-button-prev {
                left: 0;
                text-align: left;

                i {
                    transform: rotate(90deg);
                }
            }

            &.swiper-button-next {
                right: 0;
                text-align: right;

                i {
                    transform: rotate(-90deg);
                }
            }

            &.swiper-button-disabled {
                display: none;
            }
        }
    }
}
