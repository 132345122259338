.spinnerInvestment {
    input {
        max-width: 200px;
        border-radius: 0;
        border: 1px $lighter solid;
        height: 46px;
    }

    .input-group-append {
        border: 1px $lighter solid;
        height: 46px;
        width: 46px;
        margin-left: 5px;
        color: $default;
        background: $lightest;
        text-align: center;
        font-size: 22px;
        line-height: 46px;
        padding: 0 11px;
        cursor: pointer;
    }
}