@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../fonts/titilliumweb/titilliumweb-black.woff2') format('woff2'), 
         url('../../fonts/titilliumweb/titilliumweb-black.woff') format('woff'), 
         url('../../fonts/titilliumweb/titilliumweb-black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../fonts/titilliumweb/titilliumweb-bold.woff2') format('woff2'), 
         url('../../fonts/titilliumweb/titilliumweb-bold.woff') format('woff'), 
         url('../../fonts/titilliumweb/titilliumweb-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../fonts/titilliumweb/titilliumweb-bolditalic.woff2') format('woff2'), 
         url('../../fonts/titilliumweb/titilliumweb-bolditalic.woff') format('woff'), 
         url('../../fonts/titilliumweb/titilliumweb-bolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../fonts/titilliumweb/titilliumweb-semibold.woff2') format('woff2'), 
         url('../../fonts/titilliumweb/titilliumweb-semibold.woff') format('woff'), 
         url('../../fonts/titilliumweb/titilliumweb-semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../fonts/titilliumweb/titilliumweb-semibolditalic.woff2') format('woff2'), 
         url('../../fonts/titilliumweb/titilliumweb-semibolditalic.woff') format('woff'), 
         url('../../fonts/titilliumweb/titilliumweb-semibolditalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../fonts/titilliumweb/titilliumweb-regular.woff2') format('woff2'), 
         url('../../fonts/titilliumweb/titilliumweb-regular.woff') format('woff'), 
         url('../../fonts/titilliumweb/titilliumweb-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../fonts/titilliumweb/titilliumweb-italic.woff2') format('woff2'), 
         url('../../fonts/titilliumweb/titilliumweb-italic.woff') format('woff'), 
         url('../../fonts/titilliumweb/titilliumweb-italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../fonts/titilliumweb/titilliumweb-light.woff2') format('woff2'), 
         url('../../fonts/titilliumweb/titilliumweb-light.woff') format('woff'), 
         url('../../fonts/titilliumweb/titilliumweb-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../fonts/titilliumweb/titilliumweb-lightitalic.woff2') format('woff2'), 
         url('../../fonts/titilliumweb/titilliumweb-lightitalic.woff') format('woff'), 
         url('../../fonts/titilliumweb/titilliumweb-lightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../fonts/titilliumweb/titilliumweb-extralight.woff2') format('woff2'), 
         url('../../fonts/titilliumweb/titilliumweb-extralight.woff') format('woff'), 
         url('../../fonts/titilliumweb/titilliumweb-extralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../fonts/titilliumweb/titilliumweb-extralightitalic.woff2') format('woff2'), 
         url('../../fonts/titilliumweb/titilliumweb-extralightitalic.woff') format('woff'), 
         url('../../fonts/titilliumweb/titilliumweb-extralightitalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}