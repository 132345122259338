$itemsHeight: 111vw;
$itemsHeightSm: 1575px;
$itemsBackgroundPosition: center 10.5vw;
$itemsBackgroundPositionSm: left 60px;
$itemsBackgroundSize: 24.5vw auto;
$itemsBackgroundSizeSm: 65px auto;

$itemHeight: 19vw;
$itemHeightSm: 165px;
$itemWidth: 35vw;
$itemWidthSm: 330px;
$itemTopBase: 1vw;
$itemTopBaseSm: 0;
$itemTop: 13.15vw;
$itemTopSm: 190px;
$itemHorizontal: 6.5vw;
$outerItemTop: 7vw;
$outerItemTopSm: 25px;
$outerItemWidth: 44vw;

$itemIconSize: 7vw;
$itemIconSizeSm: 55px;
$itemIconSizeFontSize: 4vw;
$itemIconSizeFontSizeSm: 30px;
$itemIconLeftSm: 10px;

$itemTitleHeight: 6.5vw;
$itemTitleHeightSm: 50px;
$itemTitleFontSize: 2.1vw;
$itemTitleFontSizeSm: 20px;
$itemTitlePaddingBottom: 0.5vw;
$itemTitlePaddingBottomSm: 5px;

$itemTextPadding: $itemIconSize * 1.25;
$itemTextPaddingSm: 85px;
$itemTextPaddingTop: 1.5vw;
$itemTextPaddingTopSm: 15px;
$itemTextFontSize: 0.9vw;
$itemTextFontSizeSm: 8px;

.steps-path {
    &.negate-container-spacing {
        margin-left: -(calc($grid-gutter-width / 2));
        margin-right: -(calc($grid-gutter-width / 2));
    }

    .items {
        background-image: url('../images/layout/steps-line.svg');
        background-position: $itemsBackgroundPosition;
        background-repeat: no-repeat;
        background-size: $itemsBackgroundSize;
        height: $itemsHeight;
        position: relative;

        @include media-breakpoint-down(sm) {
            background-image: url('../images/layout/steps-line-mobile.svg');
            background-position: $itemsBackgroundPositionSm;
            background-size: $itemsBackgroundSizeSm;
            height: $itemsHeightSm;
        }

        .item {
            height: $itemHeight;
            left: $itemHorizontal;
            position: absolute;
            width: $itemWidth;

            .icon {
                background-color: $white;
                border: 2px solid $secondary;
                border-radius: 50%;
                color: $primary;
                font-size: $itemIconSizeFontSize;
                height: $itemIconSize;
                line-height: $itemIconSize;
                pointer-events: none;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
                width: $itemIconSize;
                z-index: 10;
            }

            .title {
                display: flex;
                flex-direction: column;
                height: $itemTitleHeight;
                justify-content: flex-end;
                padding-bottom: $itemTitlePaddingBottom;
                padding-right: $itemTextPadding;
                position: relative;

                &::after {
                    background-color: $secondary;
                    bottom: 0;
                    content: '';
                    display: block;
                    height: 1px;
                    left: 0;
                    pointer-events: none;
                    position: absolute;
                    width: calc(100% - #{calc($itemIconSize / 2)});
                }

                h2 {
                    font-size: $itemTitleFontSize;
                    font-weight: 700;
                    line-height: 1;
                    margin: 0;
                    text-transform: none;
                }
            }

            .text {
                font-family: $mainFont;
                font-size: $itemTextFontSize;
                margin: 0 !important;
                padding-right: $itemTextPadding;
                padding-top: $itemTextPaddingTop;
                text-align: left !important;

                p {
                    margin-bottom: $itemTextPaddingTop;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &:nth-child(even) {
                left: auto;
                right: $itemHorizontal;

                .icon {
                    left: 0;
                    right: auto;
                }

                .title {
                    padding-left: $itemTextPadding;
                    padding-right: 0;

                    &::after {
                        left: auto;
                        right: 0;
                    }
                }

                .text {
                    padding-left: $itemTextPadding;
                    padding-right: 0;

                    p {
                        margin-bottom: 1rem;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &:first-child {
                top: $outerItemTop;
                width: $outerItemWidth;
            }

            &:nth-child(2) {
                top: $itemTopBase + $itemTop;
            }

            &:nth-child(3) {
                top: $itemTopBase + ($itemTop * 2);
            }

            &:nth-child(4) {
                top: $itemTopBase + ($itemTop * 3);
            }

            &:nth-child(5) {
                top: $itemTopBase + ($itemTop * 4);
            }

            &:nth-child(6) {
                top: $itemTopBase + ($itemTop * 5);
            }

            &:nth-child(7) {
                top: $itemTopBase + ($itemTop * 6);
            }

            &:last-child {
                top: $itemTopBase + ($itemTop * 6) + $outerItemTop;
                width: $outerItemWidth;
            }

            @include media-breakpoint-down(sm) {
                height: $itemHeightSm;
                left: 0;
                max-width: 100%;
                width: $itemWidthSm;

                .icon {
                    font-size: $itemIconSizeFontSizeSm;
                    height: $itemIconSizeSm;
                    left: 0;
                    line-height: $itemIconSizeSm;
                    right: auto;
                    width: $itemIconSizeSm;
                }

                .title {
                    height: $itemTitleHeightSm;
                    padding-bottom: $itemTitlePaddingBottomSm;
                    padding-left: $itemTextPaddingSm;
                    padding-right: 0;

                    &::after {
                        left: auto;
                        right: 0;
                        width: calc(100% - #{$itemTextPaddingSm});
                    }

                    h2 {
                        font-size: $itemTitleFontSizeSm;
                    }
                }

                .text {
                    font-size: $itemTextFontSizeSm;
                    padding-left: $itemTextPaddingSm;
                    padding-right: 0;
                    padding-top: $itemTextPaddingTopSm;
                }

                &:nth-child(even) {
                    left: 0;
                    right: auto;

                    .icon {
                        left: $itemIconLeftSm;
                    }

                    .title {
                        padding-left: $itemTextPaddingSm;
                    }

                    .text {
                        padding-left: $itemTextPaddingSm;
                    }
                }

                &:first-child {
                    top: $outerItemTopSm;
                    width: $itemWidthSm;
                }

                &:nth-child(2) {
                    top: $itemTopSm;
                }

                &:nth-child(3) {
                    top: ($itemTopSm * 2);
                }

                &:nth-child(4) {
                    top: ($itemTopSm * 3);
                }

                &:nth-child(5) {
                    top: ($itemTopSm * 4);
                }

                &:nth-child(6) {
                    top: ($itemTopSm * 5);
                }

                &:nth-child(7) {
                    top: ($itemTopSm * 6);
                }

                &:last-child {
                    top: ($itemTopSm * 7);
                    width: $itemWidthSm;
                }
            }
        }
    }

    &.alt-colors {
        .items {
            background-image: url('../images/layout/steps-line-alt.svg');

            @include media-breakpoint-down(sm) {
                background-image: url('../images/layout/steps-line-mobile-alt.svg');
            }

            .item {
                .icon {
                    border-color: $light;
                }

                .title {
                    &::after {
                        background-color: $light;
                    }
                }
            }
        }
    }
}
