#rangeSelector {

    .rangeCustomInput, .rangeCustomInput:disabled{
        width: 100%;
        border: 1px $light solid;
        border-radius: 4px;
        background: transparent;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        color: #303030;
    }
    
    .customRangeSelector {
        appearance: none;
        width: 100%;
        height: 10px;
        background: $blue;
        outline: none;
        margin: 15px 0;

        &::-webkit-slider-thumb {
            appearance: none;
            width: 20px;
            height: 40px;
            border-radius: 10px;
            background: $yellow;
            cursor: pointer;
        }
        
        &::-moz-range-thumb {
            width: 20px;
            height: 40px;
            background: $yellow;
            cursor: pointer;
        }
    }
    
    b {
        color: $dark;
    }
}