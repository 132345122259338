&__message {
    border: 1px solid $secondary;
    margin-top: 60px;
    padding: 25px 25px 25px 25px;
    position: relative;

    &--success,
    &--warning,
    &--error {
        padding-left: 80px;

        &::before {
            align-items: center;
            display: flex;
            font-family: 'dagobertinvest';
            font-size: 30px;
            height: 100%;
            left: 25px;
            pointer-events: none;
            position: absolute;
            top: 0;
        }
    }

    &--success {
        border-color: #29bd07;

        &::before {
            color: #29bd07;
            content: '\e031';
        }
    }

    &--warning {
        &::before {
            color: $secondary;
            content: '\e034';
        }
    }

    &--error {
        border-color: #ff1400;

        &::before {
            color: #ff1400;
            content: '\e034';
        }
    }
}
