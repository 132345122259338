$itemMarginTopXs: 40px;

$itemActionMarginTop: 40px;
$itemActionMarginTopSm: 30px;
$itemActionMarginTopXs: 20px;
$itemTitleFontSize: 26px;
$itemTitleFontSizeSm: 22px;
$itemTitleFontSizeXs: 18px;

.actions {
    @include section-spacing(false, true);

    .item {
        text-align: center;

        &:not(:first-child) {
            @include media-breakpoint-down(xs) {
                margin-top: $itemMarginTopXs;
            }
        }

        .title {
            color: $darkBlue;
            font-family: $mainFont;
            font-size: $itemTitleFontSize;
            font-weight: 600;
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
                font-size: $itemTitleFontSizeSm;
            }

            @include media-breakpoint-down(xs) {
                font-size: $itemTitleFontSizeXs;
            }
        }

        .action {
            margin-top: $itemActionMarginTop;

            @include media-breakpoint-down(sm) {
                margin-top: $itemActionMarginTopSm;
            }

            @include media-breakpoint-down(xs) {
                margin-top: $itemActionMarginTopXs;
            }
        }
    }
}
