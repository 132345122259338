#welcome {
    padding-bottom: 45px;
    
    .hello {
        text-transform: uppercase;
        font-size: 32px;
        font-family: $font-alt;
        font-weight: 300;
        margin-top: 60px;
        margin-bottom: 20px;
    }
}