.column1 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    padding: 0 15px;
    font-family: $navigationFont;

    @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.column2 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    padding: 0 15px;

    @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
        height: 170px;
        margin-top: 30px;
    }
}

.links {
    text-align: justify;

    img {
        width: auto;
        margin-top: 44px;
        margin-bottom: 20px;
        
        &.mobile {
            filter: grayscale(100%);

            @include media-breakpoint-down(sm) {
                filter: grayscale(0%);
            }
        }
    }

    &::after {
        content: '';
        display: inline-block;
        width: 100%;
    }
}

.bg-lightest {
    position: relative;
    height: 100%;
    color: $dark;
    font-size: 40px;
    font-family: $navigationFont;
    line-height: 1.2;

    &::before {
        position: absolute;
        font-family: $dagobertFont;
        content: '\e002';
        top: 20px;
        right: 25px;
        font-size: 21px;
        line-height: 1;
    }

    .textContainer {
        position: absolute;
        bottom: 20px;
        left: 25px;
    }

    @include media-breakpoint-down(lg) {
        font-size: 30px;
    }
}