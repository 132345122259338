.newsContainer {
    padding-bottom: 50px;
    .image {
        img {
            width: 100%;
            height: auto;
        }
    }
    .title {

    }
}