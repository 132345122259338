#projects {
    @import "projects/info";

    #currentProjects {
        h2 {
            margin-bottom: 70px;
            margin-top: 80px;
            text-align: center;
        }

        .finished {
            padding-bottom: 20px;

            img {
                width: calc(100% - 20px);
                box-shadow: 20px 20px $lightest;
            }
        }

        @include media-breakpoint-down(xs) {
            font-size: 14px;
        }
    }

    #riskFrame {
        margin-bottom: 70px;
    }

    #risk {
        margin-top: 110px;
        margin-bottom: 40px;

        @include media-breakpoint-down(lg) {
            margin-top: 100px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 90px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 80px;
            margin-bottom: 55px;
        }

        @include media-breakpoint-down(xs) {
            margin-top: 50px;
            margin-bottom: 40px;
        }
    }
}
