@charset "UTF-8";
@font-face {
  font-family: "dagobertinvest";
  src:url("../../fonts/dagobertinvest/dagobertinvest.eot?newFont=20230918");
  src:url("../../fonts/dagobertinvest/dagobertinvest.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/dagobertinvest/dagobertinvest.woff?newFont=20230918") format("woff"),
    url("../../fonts/dagobertinvest/dagobertinvest.ttf?newFont=20230918") format("truetype"),
    url("../../fonts/dagobertinvest/dagobertinvest.svg#dagobertinvest") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
    display: inline-block;
}

[data-icon]:before {
    font-family: "dagobertinvest" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    display: inline-block;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "dagobertinvest" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    display: inline-block;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-long:before {
  content: "\e000";
}
.icon-arrow-medium:before {
  content: "\e001";
}
.icon-arrow-short:before {
  content: "\e002";
}
.icon-close:before {
  content: "\e003";
}
.icon-header-watermark:before {
  content: "\e005";
}
.icon-lock:before {
  content: "\e006";
}
.icon-menu:before {
  content: "\e007";
}
.icon-play-button:before {
  content: "\e008";
}
.icon-search:before {
  content: "\e009";
}
.icon-scroll:before {
  content: "\e00a";
}
.icon-document:before {
  content: "\e00b";
}
.icon-mail:before {
  content: "\e00c";
}
.icon-phone:before {
  content: "\e00d";
}
.icon-dashboard:before {
  content: "\e00e";
}
.icon-hands:before {
  content: "\e00f";
}
.icon-user:before {
  content: "\e010";
}
.icon-docadd:before {
  content: "\e011";
}
.icon-overview:before {
  content: "\e012";
}
.icon-categories:before {
  content: "\e014";
}
.icon-minus:before {
  content: "\e015";
}
.icon-plus:before {
  content: "\e016";
}
.icon-info:before {
  content: "\e017";
}
.icon-counter-investitionen:before {
  content: "\e018";
}
.icon-counter-projekte:before {
  content: "\e019";
}
.icon-counter-vermitteltes-kapital:before {
  content: "\e01a";
}
.icon-counter-zurckbezahlt:before {
  content: "\e01b";
}
.icon-double-arrow:before {
  content: "\e01c";
}
.icon-info-1:before {
  content: "\e01d";
}
.icon-investieren:before {
  content: "\e01e";
}
.icon-laufzeit:before {
  content: "\e01f";
}
.icon-ohne-kosten:before {
  content: "\e020";
}
.icon-star:before {
  content: "\e021";
}
.icon-stars:before {
  content: "\e022";
}
.icon-zinsen:before {
  content: "\e023";
}
.icon-badge:before {
  content: "\e024";
}
.icon-building:before {
  content: "\e025";
}
.icon-experts:before {
  content: "\e026";
}
.icon-group:before {
  content: "\e027";
}
.icon-hour-glass:before {
  content: "\e028";
}
.icon-house:before {
  content: "\e029";
}
.icon-houses:before {
  content: "\e02a";
}
.icon-money-bag:before {
  content: "\e02b";
}
.icon-check:before {
  content: "\e02c";
}
.icon-trophy:before {
  content: "\e02d";
}
.icon-team:before {
  content: "\e02e";
}
.icon-shild:before {
  content: "\e02f";
}
.icon-arrow:before {
  content: "\e013";
}
.icon-contact-bubble:before {
  content: "\e004";
}
.icon-cancel:before {
  content: "\e030";
}
.icon-confirm:before {
  content: "\e031";
}
.icon-lock-alt:before {
  content: "\e032";
}
.icon-person:before {
  content: "\e033";
}
.icon-error:before {
  content: "\e034";
}
.icon-calendar:before {
  content: "\e035";
}
.icon-caret:before {
  content: "\e036";
}
.icon-eye-closed:before {
  content: "\e037";
}
.icon-eye-open:before {
  content: "\e038";
}
.icon-info-alt:before {
  content: "\e039";
}
.icon-pencil:before {
  content: "\e03a";
}
.icon-step-completed:before {
  content: "\e03b";
}
.icon-step-current:before {
  content: "\e03c";
}
.icon-step-upcoming:before {
  content: "\e03d";
}
.icon-house-pile:before {
  content: "\e03e";
}
.icon-money-pile:before {
  content: "\e03f";
}
.icon-user-check:before {
  content: "\e040";
}
.icon-check-alt:before {
  content: "\e041";
}
.icon-arrow-split:before {
  content: "\e042";
}
.icon-shortened-arrow:before {
  content: "\e043";
}
.icon-pdf:before {
  content: "\e044";
}
.icon-dot:before {
  content: "\e045";
}
