// New registration view (2023)

$passwordStrengthBorder: #e6e6e6;
$passwordStrengthWeak: #ff1400;
$passwordStrengthMedium: #fac000;
$passwordStrengthStrong: #29bd07;

.registration-fullscreen-view {
    background-color: white;
    color: $gray;
    font-family: $mainFont;
    min-height: 100vh;
    overflow: auto;
    position: relative;
    transition: height 0.3s;

    &::before {
        background-color: $almostWhite;
        content: '';
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 33.33333333%;
    }

    &__header {
        padding: 40px (40px - calc($grid-gutter-width / 2)) 0;

        &__logo {
            text-align: center;

            a {
                display: inline-block;

                img {
                    display: block;
                    max-width: 100%;
                    width: 130px;
                }
            }
        }

        &__close {
            text-align: right;

            a {
                color: inherit;
                font-size: 24px;
                line-height: 1;
            }
        }
    }

    &__content {
        overflow-x: hidden; // We explicitly hide overflowing content because otherwise the large custom gutters would cause overflows due to the negative margins
        padding: 30px 0 100px;

        &__sidebar {
            &__logo {
                a {
                    display: inline-block;

                    img {
                        display: block;
                        max-width: 100%;
                        width: 350px;
                    }
                }
            }

            &__steps {
                margin-top: 90px;
                pointer-events: none;

                ol {
                    align-items: flex-end;
                    display: flex;
                    flex-direction: column;
                    gap: 70px;
                    list-style: none;
                    margin: 0;
                    padding: 80px 0;
                    position: relative;

                    &::after {
                        background-color: $accentGray;
                        bottom: 0;
                        content: '';
                        display: block;
                        position: absolute;
                        right: 25px;
                        top: 0;
                        width: 1px;
                        z-index: 1;
                    }

                    li {
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 50px;
                        max-width: 100%;
                        position: relative;
                        width: 225px;
                        z-index: 2;

                        &::after {
                            background-color: $white;
                            border-radius: 50%;
                            color: $accentGray;
                            font-family: $dagobertFont;
                            font-size: 50px;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &.current-step {
                            color: $secondary;

                            &::after {
                                color: inherit;
                                content: '\e03c';
                            }
                        }

                        &.upcoming-step {
                            &::after {
                                content: '\e03d';
                            }
                        }

                        &.completed-step {
                            color: $primary;

                            &::after {
                                color: inherit;
                                content: '\e03b';
                            }
                        }
                    }
                }
            }

            &__login {
                margin-top: 90px;
                text-align: center;
            }
        }

        &__form {
            &__steps {
                pointer-events: none;

                ol {
                    display: flex;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        display: flex;
                        flex: 1;
                        position: relative;

                        &::after {
                            color: $secondary;
                            content: '\e042';
                            font-family: $dagobertFont;
                            font-size: 32px;
                            left: 140px;
                            line-height: 140px;
                            position: absolute;
                            right: 0;
                            text-align: center;
                        }

                        &:last-child {
                            flex: 0;
                            width: 140px;

                            &::after {
                                content: none;
                            }
                        }

                        .step-content {
                            align-items: center;
                            background-color: $offWhite;
                            display: flex;
                            flex-direction: column;
                            font-size: 13px;
                            height: 140px;
                            justify-content: center;
                            width: 140px;
                            position: relative;

                            i {
                                color: $primary;
                                font-size: 36px;
                            }

                            span {
                                align-items: center;
                                display: flex;
                                height: 39px;
                                text-align: center;
                            }
                        }
                    }
                }
            }

            form {
                font-size: 15px;
                margin-top: 80px;

                .wide-gutter {
                    @include custom-gutter(80px);
                }

                .required-indicator {
                    color: $secondary;
                }

                .required-fields-info {
                    margin-top: 40px;
                }

                .activation-info {
                    border-top: 1px solid $secondary;
                    padding-top: 60px;
                    position: relative;
                    text-align: center;

                    p {
                        margin: 0;
                    }

                    .dg-info-box {
                        right: -50px;
                        top: 60px;
                    }
                }

                .registration-title {
                    border-bottom: 1px solid $secondary;
                    font-size: 21px;
                    font-weight: 300;
                    margin-top: 60px;
                    padding-bottom: 5px;
                }

                .registration-field {
                    margin-top: 40px;
                    position: relative;

                    label {
                        margin-bottom: 15px;
                    }

                    &.has-info-box {
                        .dg-info-box {
                            right: -50px;
                            top: 0;
                        }
                    }

                    &--sex {
                        display: flex;
                        gap: 60px;
                    }

                    &--birthdate {
                        .dg-input-wrapper {
                            input[type='text'] {
                                cursor: pointer;
                            }
                        }
                    }

                    &--password {
                        .dg-input-wrapper {
                            i {
                                cursor: pointer;
                                font-size: 200%;
                                pointer-events: auto !important;
                            }
                        }
                    }

                    &--agree-to-privacy-policy {
                        margin-top: 20px;
                    }
                }

                .password-strength {
                    display: flex;
                    margin-top: 15px;
                    pointer-events: none;

                    &__indicators {
                        margin-left: 20px;
                    }

                    &__indicator {
                        align-items: center;
                        display: none;

                        &--active {
                            display: flex;
                        }

                        &__icon {
                            background-clip: content-box;
                            box-sizing: content-box;
                            height: 14px;
                            padding-right: 38px;
                            position: relative;
                            width: 14px;

                            &::before,
                            &::after {
                                border: 1px solid $passwordStrengthBorder;
                                content: '';
                                display: block;
                                height: 14px;
                                position: absolute;
                                top: 0;
                                width: 14px;
                            }

                            &::before {
                                left: 19px;
                            }

                            &::after {
                                left: 38px;
                            }
                        }

                        &__label {
                            margin-left: 15px;
                        }

                        &--weak {
                            color: $passwordStrengthWeak;

                            .password-strength__indicator__icon {
                                background-color: $passwordStrengthWeak;
                            }
                        }

                        &--medium {
                            color: $passwordStrengthMedium;

                            .password-strength__indicator__icon {
                                background-color: $passwordStrengthMedium;

                                &::before {
                                    background-color: $passwordStrengthMedium;
                                    border-color: transparent;
                                }
                            }
                        }

                        &--strong {
                            color: $passwordStrengthStrong;

                            .password-strength__indicator__icon {
                                background-color: $passwordStrengthStrong;

                                &::before,
                                &::after {
                                    background-color: $passwordStrengthStrong;
                                    border-color: transparent;
                                }
                            }
                        }
                    }
                }

                .password-requirements-info {
                    font-size: 13px;
                    margin-top: 15px;
                }

                .registration-submit {
                    margin-top: 70px;
                    text-align: center;
                }
            }

            .registration-confirmation {
                font-size: 15px;

                p {
                    margin: 0;
                }

                &__title {
                    h1 {
                        color: $primary;
                        font-size: 36px;
                        margin: 0;
                        text-align: center;
                    }
                }

                &__intro {
                    margin-top: 30px;
                    text-align: center;
                }

                &__welcome-gift {
                    margin-top: 50px;

                    &__title {
                        align-items: end;
                        border-bottom: 1px solid $secondary;
                        display: flex;
                        font-size: 18px;
                        padding-bottom: 10px;

                        &::before {
                            color: $secondary;
                            content: '\e03f';
                            font-family: $dagobertFont;
                            font-size: 200%;
                            line-height: 1;
                            margin-right: 20px;
                        }

                        h2 {
                            color: $primary;
                            font-family: $mainFont;
                            font-size: inherit;
                            font-weight: 500;
                            margin: 0;
                            text-transform: uppercase;
                        }
                    }

                    &__text {
                        margin-top: 20px;
                    }
                }

                &__invest-now {
                    margin-top: 50px;
                }

                &__invest-more {
                    border-top: 1px solid $secondary;
                    margin-top: 50px;
                    padding-top: 30px;

                    &__button {
                        margin-top: 30px;
                    }
                }

                &__greeting {
                    margin-top: 50px;
                }

                &__investment-guide {
                    border-top: 1px solid #ccc;
                    margin-top: 50px;
                    padding-top: 30px;

                    &__link {
                        margin-top: 20px;

                        a {
                            align-items: end;
                            display: inline-flex;
                            position: relative;
                            text-decoration: none;

                            &::before {
                                content: '\e044';
                                font-family: $dagobertFont;
                                font-size: 200%;
                                line-height: 1;
                                margin-right: 10px;
                            }

                            span {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &__content {
            &__sidebar {
                &__steps {
                    ol {
                        padding: 60px 0;

                        &::after {
                            right: 20px;
                        }

                        li {
                            font-size: 16px;
                            line-height: 40px;

                            &::after {
                                font-size: 40px;
                            }
                        }
                    }
                }
            }

            &__form {
                &__steps {
                    ol {
                        li {
                            &::after {
                                content: '\e043';
                                font-size: 22px;
                                left: 120px;
                                line-height: 120px;
                            }

                            .step-content {
                                font-size: 11px;
                                height: 120px;
                                width: 120px;

                                i {
                                    font-size: 32px;
                                }

                                span {
                                    height: 33px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__content {
            &__sidebar {
                &__steps {
                    ol {
                        &::after {
                            right: 16px;
                        }

                        li {
                            font-size: 14px;
                            line-height: 32px;

                            &::after {
                                font-size: 32px;
                            }
                        }
                    }
                }

                &__login {
                    font-size: 14px;
                }
            }

            &__form {
                &__steps {
                    border-bottom: 1px solid $secondary;

                    ol {
                        li {
                            &::after {
                                font-size: 16px;
                                left: 100px;
                                line-height: 100px;
                            }

                            .step-content {
                                background-color: transparent;
                                font-size: 10px;
                                height: 100px;
                                width: 100px;

                                // i {
                                //     font-size: 32px;
                                // }

                                span {
                                    align-items: normal;
                                    height: 30px;
                                }
                            }
                        }
                    }
                }

                form {
                    font-size: 13px;
                    margin-top: 60px;

                    .wide-gutter {
                        @include custom-gutter(30px);
                    }

                    .activation-info {
                        border-top: 0;
                        padding-top: 0;

                        .dg-info-box {
                            right: 0;
                            top: -25px;
                        }
                    }

                    .registration-field {
                        &.has-info-box {
                            .dg-info-box {
                                right: 0;
                                top: -25px;
                            }
                        }

                        &--password {
                            .dg-input-wrapper {
                                i {
                                    font-size: 150%;
                                }
                            }
                        }
                    }
                }

                .registration-confirmation {
                    font-size: 13px;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &::before {
            content: none;
        }

        &__header {
            background-color: $almostWhite;
            padding: 15px 0 10px;

            &__close {
                a {
                    font-size: 12px;
                }
            }
        }

        &__content {
            padding-top: 0;

            &__form {
                &__steps {
                    ol {
                        li {
                            &::after {
                                font-size: 14px;
                                left: 80px;
                                line-height: 1;
                                top: 30px;
                            }

                            .step-content {
                                font-size: 9px;
                                width: 80px;

                                i {
                                    font-size: 26px;
                                }

                                span {
                                    height: 27px;
                                }
                            }
                        }
                    }
                }

                form {
                    .company-data {
                        margin-top: 20px;
                    }

                    .registration-field {
                        margin-top: 20px;

                        &.has-info-box {
                            margin-top: 40px;
                        }

                        &--sex,
                        &--project-information,
                        &--activation-code {
                            margin-top: 40px;
                        }

                        &--sex {
                            display: block;

                            > div {
                                &:first-child {
                                    margin-bottom: 15px;
                                }
                            }

                            .dg-input-wrapper {
                                margin-top: 5px;
                            }
                        }
                    }

                    .registration-title {
                        font-size: 16px;
                    }

                    .registration-submit {
                        margin-top: 50px;
                    }
                }

                .registration-confirmation {
                    padding-top: 20px;

                    &__-title {
                        h1 {
                            font-size: 18px;
                        }
                    }

                    &__welcome-gift {
                        margin-top: 40px;

                        &__title {
                            font-size: 15px;
                        }
                    }

                    &__invest-now {
                        margin-top: 40px;
                        text-align: center;
                    }

                    &__invest-more {
                        margin-top: 40px;

                        &__button {
                            text-align: center;
                        }
                    }

                    &__greeting {
                        margin-top: 40px;
                    }

                    &__investment-guide {
                        margin-top: 40px;
                    }
                }
            }
        }
    }
}
