$padding: 75px 0;
$paddingMd: 55px 0;
$paddingSm: 45px 0;

$titleFontSize: 52px;
$titleFontSizeMd: 48px;
$titleFontSizeSm: 40px;
$titleFontSizeXs: 24px;

$itemMarginTop: 75px;
$itemMarginTopMd: 55px;
$itemMarginTopSm: 45px;
$itemMarginTopXs: 30px;

$itemRatingHeight: 36px;
$itemRatingHeightSm: 30px;

$itemAuthorFontSize: 20px;
$itemAuthorFontSizeMd: 18px;
$itemAuthorFontSizeSm: 15px;
$itemAuthorFontSizeXs: 14px;
$itemAuthorMarginTop: 45px;
$itemAuthorMarginTopXs: 30px;
$itemTextFontSize: 20px;
$itemTextFontSizeMd: 18px;
$itemTextFontSizeSm: 15px;
$itemTextFontSizeXs: 14px;
$itemTextMarginTop: 45px;
$itemTextMarginTopXs: 30px;

$swiperButtonWidth: $grid-gutter-width * 2;

.customer-opinions {
    @include section-spacing(true);
    background-color: $almostWhite;
    padding: $padding;

    @include media-breakpoint-down(md) {
        padding: $paddingMd;
    }

    @include media-breakpoint-down(sm) {
        padding: $paddingSm;
    }

    .title {
        text-align: center;

        h2 {
            color: $primary;
            font-size: $titleFontSize;
            font-weight: 700;
            margin: 0;
            text-transform: none;

            @include media-breakpoint-down(md) {
                font-size: $titleFontSizeMd;
            }

            @include media-breakpoint-down(sm) {
                font-size: $titleFontSizeSm;
            }

            @include media-breakpoint-down(xs) {
                font-size: $titleFontSizeXs;
            }
        }
    }

    .items {
        padding: 0 $swiperButtonWidth;

        .item {
            margin-top: $itemMarginTop;
            position: relative;
            text-align: center;

            @include media-breakpoint-down(md) {
                margin-top: $itemMarginTopMd;
            }

            @include media-breakpoint-down(sm) {
                margin-top: $itemMarginTopSm;
            }

            @include media-breakpoint-down(xs) {
                margin-top: $itemMarginTopXs;
            }

            .rating {
                img {
                    display: block;
                    height: $itemRatingHeight;
                    margin: 0 auto;

                    @include media-breakpoint-down(sm) {
                        height: $itemRatingHeightSm;
                    }
                }
            }

            .text {
                font-family: $mainFont;
                font-size: $itemTextFontSize;
                font-weight: 300;
                margin-top: $itemTextMarginTop;

                @include media-breakpoint-down(md) {
                    font-size: $itemTextFontSizeMd;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $itemTextFontSizeSm;
                }

                @include media-breakpoint-down(xs) {
                    font-size: $itemTextFontSizeXs;
                    margin-top: $itemTextMarginTopXs;
                }

                p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .author {
                color: $darkGray;
                font-family: $mainFont;
                font-size: $itemAuthorFontSize;
                font-style: italic;
                font-weight: 600;
                margin-top: $itemAuthorMarginTop;

                @include media-breakpoint-down(md) {
                    font-size: $itemAuthorFontSizeMd;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $itemAuthorFontSizeSm;
                }

                @include media-breakpoint-down(xs) {
                    font-size: $itemAuthorFontSizeXs;
                    margin-top: $itemAuthorMarginTopXs;
                }
            }

            .more {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }

        .swiper-button {
            @include input-reset;
            background-color: $almostWhite;
            color: $light;
            cursor: pointer;
            font-size: 2em;
            height: 100%;
            position: absolute;
            top: 0;
            width: $swiperButtonWidth;
            z-index: 10;

            &.swiper-button-prev {
                left: 0;
                text-align: left;

                i {
                    transform: rotate(90deg);
                }
            }

            &.swiper-button-next {
                right: 0;
                text-align: right;

                i {
                    transform: rotate(-90deg);
                }
            }

            &.swiper-button-disabled {
                display: none;
            }
        }
    }
}
