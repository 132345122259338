$height: 490px;
$heightSm: 420px;
$heightXs: 360px;
$paddingTop: 40px;

$titleFontSize: 50px;
$titleFontSizeMd: 46px;
$titleFontSizeSm: 38px;
$titleFontSizeXs: 24px;

$actionMarginTop: 60px;
$actionMarginTopSm: 50px;
$actionMarginTopXs: 20px;

.closed-projects {
    @include section-spacing(true, true);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: $height;
    padding-top: $paddingTop;

    @include media-breakpoint-down(sm) {
        height: $heightSm;
    }

    @include media-breakpoint-down(xs) {
        height: $heightXs;
    }

    .title {
        text-align: center;

        h2 {
            color: $primary;
            font-size: $titleFontSize;
            margin: 0;

            @include media-breakpoint-down(md) {
                font-size: $titleFontSizeMd;
            }

            @include media-breakpoint-down(sm) {
                font-size: $titleFontSizeSm;
            }

            @include media-breakpoint-down(xs) {
                font-size: $titleFontSizeXs;
            }
        }
    }

    .action {
        margin-top: $actionMarginTop;
        text-align: center;

        @include media-breakpoint-down(sm) {
            margin-top: $actionMarginTopSm;
        }

        @include media-breakpoint-down(xs) {
            margin-top: $actionMarginTopXs;
        }

        &:first-child {
            margin-top: 0;
        }
    }
}
