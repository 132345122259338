.numberOfFriends {
    position: relative;

    img {
        width: 100%;
        height: auto;
    }

    .dataOverImage {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        color: white;
        display: block;
        width: 100%;
        font-family: $navigationFont;
        font-size: 34px;
        font-weight: 300;

        .number {
            font-family: $font-alt;
            font-size: 90px;
            font-weight: 400;
            line-height: 1;
        }
    }
}

.height100 {
    height: 100%;
}