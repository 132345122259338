.faq {
    img {
        width: 100%;
        height: auto;
    }
    h3.link {
        text-transform: none;

        .faqLink {
            position: relative;
            display: block;
            font-size: 28px;
            font-family: $navigationFont;
            margin-bottom: 50px;
            color: $dark;
            padding-left: 65px;

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                content: '\e002';
                font-family: $dagobertFont;
                font-size: 32px;
                line-height: 45px;
            }

            &:hover {
                color: $blue;
                text-decoration: none;

                &::before {
                    color: $yellow;
                }
            }

            @include media-breakpoint-down(xs) {
                font-size: 22px;
            }
        }
    }

    #faqContent {
        h3 {
            position: relative;
            display: block;
            font-size: 28px;
            font-family: $navigationFont;
            color: $dark;
            padding-left: 35px;
            margin: 30px 0;

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                content: '\e013';
                font-family: $dagobertFont;
                font-size: 20px;
                line-height: 35px;
                transition: all 0.25s linear;
            }

            &.collapsed {
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '\e013';
                    font-family: $dagobertFont;
                    font-size: 20px;
                    line-height: 35px;
                    transform: rotate(-90deg);
                }
            }
        }

        .answer {
            padding-left: 35px;
        }
    }

    .faq-items {
        @include section-spacing-bottom(true, false);

        .faq-item {
            margin-top: 40px;

            @include media-breakpoint-down(xs) {
                margin-top: 20px;
            }

            .title {
                cursor: pointer;
                padding-left: 60px;
                position: relative;

                @include media-breakpoint-down(xs) {
                    padding-left: 40px;
                }

                &::before {
                    border: 1px solid $secondary;
                    content: '-';
                    color: $primary;
                    display: block;
                    font-size: 26px;
                    height: 30px;
                    left: 0;
                    line-height: 30px;
                    position: absolute;
                    text-align: center;
                    top: 0;
                    width: 30px;

                    @include media-breakpoint-down(xs) {
                        font-size: 18px;
                        line-height: 22px;
                        height: 22px;
                        width: 22px;
                    }
                }

                &.collapsed {
                    &::before {
                        content: '+';
                    }
                }

                h2 {
                    border-bottom: 1px solid $secondary;
                    color: $primary;
                    font-family: $navigationFont;
                    font-size: 26px;
                    font-weight: 600;
                    line-height: 30px;
                    margin: 0;
                    padding-bottom: 5px;
                    text-transform: none;

                    @include media-breakpoint-down(xs) {
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }

            .content {
                margin: 20px 0 0 60px;

                @include media-breakpoint-down(xs) {
                    margin: 10px 0 0 0;
                }

                #faqContent {
                    h3 {
                        color: $primary;
                        cursor: pointer;
                        font-family: $mainFont;
                        font-size: 18px;
                        margin: 20px 0 0;
                        line-height: 35px;
                        text-transform: none;

                        &:first-child {
                            margin-top: 0;
                        }

                        &::before {
                            color: $secondary;
                            font-size: inherit;
                            line-height: inherit;
                        }

                        @include media-breakpoint-down(xs) {
                            font-size: 14px;
                            line-height: 20px;
                            padding-left: 40px;
                        }
                    }

                    .answer {
                        border-bottom: 1px solid $secondary;
                        border-top: 1px solid $secondary;
                        font-family: $mainFont;
                        font-size: 15px;
                        margin: 10px 0 0 35px;
                        padding: 20px 0;

                        @include media-breakpoint-down(xs) {
                            font-size: 12px;
                            margin: 10px 0 0 40px;
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
    }
}
