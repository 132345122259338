.completed-projects {
    @include section-spacing;

    .title {
        @include title-with-icon;
    }

    .projects {
        .swiper-slide {
            height: auto;
        }
    }

    .action {
        @include frontpage-action;
    }
}
