#messages {
    .bg-white {
        height: 100%;
        padding: 20px 25px 20px 0;

        @include media-breakpoint-down(sm) {
            padding: 20px 25px;
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    .title {
        font-family: "Exo2";
        font-size: 20px;
        color: $lessDark;
        margin-bottom: 30px;
    }

    @import "messages/leftCol";
    @import "messages/rightCol";
}