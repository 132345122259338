$inputWrapperMarginTop: 25px;
$inputWrapperMarginTopLarge: 50px;
$inputWrapperMarginTopLargeSm: 40px;
$inputWrapperMarginTopLargeXs: 30px;

$inputFontSize: 18px;
$inputFontSizeSm: 16px;
$inputFontSizeXs: 14px;
$inputPadding: 10px 15px;

$labelFontSize: 16px;
$labelFontSizeSm: 14px;

$checkboxLabelPaddingLeft: 45px;
$checkboxSize: 24px;

.newsletter-registration-form {
    .input-wrapper {
        margin-top: $inputWrapperMarginTop;

        &:first-child {
            margin-top: 0;
        }

        input[type='text'],
        input[type='email'] {
            border: 1px solid $lighter;
            color: $default;
            font-family: $mainFont;
            font-size: $inputFontSize;
            padding: $inputPadding;
            width: 100%;

            &:focus {
                outline: 0 !important;
            }

            &::placeholder {
                color: $gray;
            }

            @include media-breakpoint-down(sm) {
                font-size: $inputFontSizeSm;
            }

            @include media-breakpoint-down(xs) {
                font-size: $inputFontSizeXs;
            }
        }

        &.input-wrapper-large-spacing {
            margin-top: $inputWrapperMarginTopLarge;

            @include media-breakpoint-down(sm) {
                margin-top: $inputWrapperMarginTopLargeSm;
            }

            @include media-breakpoint-down(xs) {
                margin-top: $inputWrapperMarginTopLargeXs;
            }
        }

        &.checkbox-input-wrapper {
            position: relative;

            label {
                font-family: $mainFont;
                font-size: $labelFontSize;
                padding-left: $checkboxLabelPaddingLeft;
                position: relative;

                &::before {
                    border: 1px solid $lighter;
                    color: $default;
                    cursor: pointer;
                    content: '';
                    font-family: $mainFont;
                    height: $checkboxSize;
                    line-height: $checkboxSize;
                    left: 0;
                    position: absolute;
                    text-align: center;
                    width: $checkboxSize;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $labelFontSizeSm;
                }
            }

            input[type='checkbox'] {
                position: absolute;
                visibility: hidden;

                &:checked {
                    + label {
                        &::before {
                            content: '✓';
                        }
                    }
                }
            }
        }
    }

    button[type='submit'] {
        width: 100% !important;
    }

    .message {
        border: 1px solid;
        color: $secondary;
        font-family: $mainFont;
        font-size: $labelFontSize;
        margin-top: $inputWrapperMarginTopLarge;
        padding: 1em;

        @include media-breakpoint-down(sm) {
            margin-top: $inputWrapperMarginTopLargeSm;
        }

        @include media-breakpoint-down(xs) {
            margin-top: $inputWrapperMarginTopLargeXs;
        }

        &:first-child {
            margin-top: 0;
        }

        &.message-is-success {
            color: $green;
        }

        &.message-is-error {
            color: $red;
        }
    }
}
