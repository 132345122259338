#riskFrame {
    color: $dark;
    font-size: 16px;

    &.de {
        b {
            font-size: 18px;
        }
    }

    .frame {
        border: 1px solid $dark;
        padding: 20px;

        .riskFrameTitle {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: -55px;
            margin-bottom: 10px;
            font-weight: 700;
            width: 300px;
            font-size: 28px;
            background: $almostWhite;
        }

        a {
            color: $mediumBlue;
            text-decoration: underline;
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: 14px;

        .frame {

            .title {
                margin-top: -45px;
                width: 200px;
                font-size: 20px;
    
                &.de {
                    width: 550px;
                }
            }
    
            a {
                color: $mediumBlue;
                text-decoration: underline;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        font-size: 12px;

        .frame {

            .title {
                margin-top: -35px;
                width: 200px;
                font-size: 18px;
                line-height: 1;
    
                &.de {
                    width: 100%;
                }
            }
    
            a {
                color: $mediumBlue;
                text-decoration: underline;
            }
        }
    }
}